import { useContext, useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Link, useRouteMatch } from "react-router-dom";

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Scroll from 'react-scroll';
import ProductItem from "./ProductItem";
import { CurrentPContext, OrderContext, ScreenWidthContext } from "../../ContextApi/context";
import { MenuItem, MenuList } from "@mui/material";
import DayOpeningHours from "./RestDetails/DayOpeningHours";
import AddressLine from "./RestDetails/addressLine";
import BuisRating from "./RestDetails/BuisRating";
import ProductItemDeskTop from "./ProductItemDeskTop";
import { FiberManualRecord } from "@mui/icons-material";
import MyDateTime from "../../DTechComp/MyDateTime";

var scroller = Scroll.scroller;


const RestMnu = (props) => {
    let { path, url } = useRouteMatch();
    const [currentPage] = useContext(CurrentPContext);
    const [order, setOrder] = useContext(OrderContext);
    const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);
    const [value, setValue] = useState(0);
    // const [restProducts, setRestProducts] = useState([...props.Products]);
    const [sender, setSender] = useState(null);

    const RestMnuRef = useRef(0)
    const [restMnuFxClass, setRestMnuFxClass] = useState("noFix");
    const [restMnuSpClass, setRestMnuSpClass] = useState("");

    const [mnuDivsPadd, setMnuDivsPadd] = useState("noPadd");
    const [buisMenu, setBuisMenu] = useState({})

    const [searchTxt, setSearchTxt] = useState("")
    const [searchMode, setSearchMode] = useState(false)

    const [onlyPhone, setOnlyPhone] = useState();
    // const [onlyPhone, setOnlyPhone] = useState(false);

    useEffect(() => {


        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);


    }, []);

    useEffect(() => {

        if (!props.doDeliver && !props.doTakeAway) {
            setOnlyPhone(true)
        }
        else if (!MyDateTime.checkIfBetweenHours(currentPage).openOrCLose) {
            setOnlyPhone(true)
        }




    }, [])

    const handleScroll = () => {

        const currentScrollY = window.scrollY;
        const fotterPos = window.document.getElementsByClassName("BottomMast")[0].offsetTop;
        if (RestMnuRef.current) {

            if (currentScrollY > RestMnuRef.current.offsetTop && currentScrollY < fotterPos - 500) {
                setRestMnuFxClass("fixPos");
                setRestMnuSpClass("PaddMeFix");
                setMnuDivsPadd("withPadd");
            }
            else {
                setRestMnuFxClass("noFix");
                setRestMnuSpClass("");
                setMnuDivsPadd("noPadd");
            }

            spyTheBlock(currentScrollY, RestMnuRef.current.offsetTop - 100);
        }
    }

    const spyTheBlock = (currentScrollY, startMnus) => {
        let alldivBlocks = window.document.getElementsByClassName("divBlock");

        if (startMnus < currentScrollY) {
            for (let i = 0; i < alldivBlocks.length; i++) {
                const element = alldivBlocks[i];
                if (currentScrollY < element.offsetTop + element.offsetHeight - 150) {
                    setValue(i);
                    break;
                }
            }
        }

    }


    const handleChange = (newValue) => {
        setValue(newValue);
        scroller.scrollTo(`divBlock_${newValue}`, { smooth: true, offset: -140, duration: 1000 });

    };


    const handlebackToPos = (theID) => {

        scroller.scrollTo(`${theID}`, { smooth: true, offset: -100, duration: 1000 });

    };

    const serachMnuItm = (txtSearch) => {
        setSearchTxt(txtSearch)

    }

    return (

        <div className="RestMnu" ref={RestMnuRef} >

            {/* טאבים ימין בדסקטופ */}
            {(screenWidth > 800) ?
                <div className={"rstMnuRight"}>
                    <div className={`tabsHeadPlace ${restMnuFxClass}`}>
                        {(searchTxt == "") ? <div className={"fxPosWrap"}>
                            <Box sx={{ bgcolor: 'white', direction: 'rtl' }}>
                                <MenuList
                                    orientation={"vertical"}
                                    value={value}
                                    // onChange={handleChange}

                                    aria-label="תפריט"
                                >
                                    {
                                        props.buisMenu.map((item, index) => {
                                            return (
                                                <MenuItem key={index} onClick={() => handleChange(index)}
                                                    className={`tabClass ${(value == index) ? "selcedMnuIt" : ""}`}
                                                    style={{ color: "black" }} label={item.DivName}>{item.DivName}</MenuItem>)
                                        })
                                    }
                                </MenuList>
                            </Box>
                        </div> : ""}
                    </div>
                </div> : ""
            }

            <div className={`rstMnuWrap ${restMnuSpClass} ${(searchTxt == "") ? "" : "PaddMeFix"}`}>
                <div className={"serachOnMnu"}>
                    {(searchTxt != "") ? <div onClick={() => serachMnuItm("")} ><HighlightOffIcon className="clearSrch" /> </div> : ""}
                    <input type={"text"} placeholder={`חפש בתפריט של ${props.nameBuis}`} value={searchTxt} onChange={(e) => serachMnuItm(e.target.value)} />
                    <img id="searchBtn" src="/PermanentImages/searchIc.gif" alt="" />
                </div>

                {(screenWidth <= 800) ?
                    <div className={`tabsHeadPlace ${restMnuFxClass}`}>
                        {(searchTxt == "") ? <div className={"fxPosWrap"}>
                            <Box sx={{ bgcolor: 'white', direction: 'rtl' }}>
                                {(restMnuFxClass == "fixPos") ?
                                    <h2 className={"headOnFix bold SecondFontRegular big"}>
                                        {currentPage.buissName}
                                    </h2> : ""}
                                <Tabs
                                    value={value}
                                    // onChange={handleChange}
                                    variant="scrollable"
                                    aria-label="תפריט"
                                >
                                    {
                                        props.buisMenu.map((item, index) => {
                                            return (
                                                <Tab key={index}
                                                    onClick={() => handleChange(index)}
                                                    className={"tabClass"}
                                                    style={{ color: "black" }} label={item.DivName} />)
                                        })
                                    }
                                </Tabs>
                            </Box>
                        </div> : ""}
                    </div> : ""}

                {(searchTxt == "") ?
                    <div className={`mnuDivs ${mnuDivsPadd}`}>
                        {

                            props.buisMenu.map((item, index) => {

                                let divsBk = item.Products.map((divitm, divIndex) => {
                                    if (!props.dispOnlyDelivery || divitm.okToDeliver) {
                                        if (screenWidth <= 800) {
                                            return (
                                                <ProductItem key={`${divIndex}_${index}`}
                                                    dops={0} itemId={`${divIndex}_${index}`}
                                                    divitm={divitm}
                                                    doDeliver={props.doDeliver}
                                                    doTakeAway={props.doTakeAway}
                                                    setSender={(senderId) => setSender(senderId)}
                                                    sender={sender}
                                                    dispOnlyDelivery={props.dispOnlyDelivery}
                                                    onlyPhone={onlyPhone}
                                                    myClass={(!divitm.inStock) ? "outOfStock" : ""}
                                                />
                                            )
                                        }
                                        else {
                                            return (
                                                <ProductItemDeskTop key={`${divIndex}_${index}`}
                                                    dops={0} itemId={`${divIndex}_${index}`}
                                                    doDeliver={props.doDeliver}
                                                    doTakeAway={props.doTakeAway}
                                                    divitm={divitm}
                                                    setSender={(senderId) => setSender(senderId)}
                                                    sender={sender}
                                                    dispOnlyDelivery={props.dispOnlyDelivery}
                                                    onlyPhone={onlyPhone}
                                                    myClass={(!divitm.inStock) ? "outOfStock" : ""}
                                                />
                                            )
                                        }
                                    }
                                });

                                let itmDesc = (item.DivDescript) ?
                                    <div className={`${(screenWidth <= 800) ? "regular divDescriptition" :
                                        "big"} bold SecondFontRegular`}>
                                        {item.DivDescript}</div> : "";

                                return (
                                    <div key={index} id={`divBlock_${index}`} className="divBlock">
                                        <h2 className={`divHead ${(screenWidth <= 800) ? "veryBig" : "anak"}
                                      bold SecondFontRegular`}> {item.DivName}</h2>
                                        {itmDesc}

                                        {divsBk}
                                    </div>
                                )
                            })}
                    </div>
                    :
                    <div className={`mnuDivs ${mnuDivsPadd}`}>
                        {
                            props.buisMenu.map((item, index) => {
                                var cnt = 0;
                                let divsBk = item.Products.map((divitm, divIndex) => {

                                    if (divitm.prodName.includes(searchTxt)) {
                                        cnt++;

                                        if (!props.dispOnlyDelivery || divitm.okToDeliver) {
                                            if (divitm.inStock) {
                                                if (screenWidth <= 800) {
                                                    return (
                                                        <ProductItem key={`${divIndex}_${index}`}
                                                            dops={0} itemId={`${divIndex}_${index}`}
                                                            divitm={divitm}
                                                            doDeliver={props.doDeliver}
                                                            doTakeAway={props.doTakeAway}
                                                            setSender={(senderId) => setSender(senderId)}
                                                            sender={sender}
                                                            dispOnlyDelivery={props.dispOnlyDelivery}
                                                            onlyPhone={onlyPhone} />
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <ProductItemDeskTop key={`${divIndex}_${index}`}
                                                            dops={0} itemId={`${divIndex}_${index}`}
                                                            doDeliver={props.doDeliver}
                                                            doTakeAway={props.doTakeAway}
                                                            divitm={divitm}
                                                            setSender={(senderId) => setSender(senderId)}
                                                            sender={sender}
                                                            dispOnlyDelivery={props.dispOnlyDelivery}
                                                            onlyPhone={onlyPhone} />
                                                    )
                                                }
                                            }
                                        }


                                    }
                                });
                                if (parseInt(cnt) > 0) {
                                    return <div key={index} id={`divBlock_${index}`} className="divBlock">
                                        <h2 className={"divHead veryBig bold SecondFontRegular"}> {cnt.length}{item.DivName}</h2>
                                        {divsBk}
                                    </div>
                                }
                            })}
                    </div>
                }
            </div>

            {/* שמאל בדסקטופ */}
            {
                (screenWidth > 800) ? <div className={"rstMnuLeft"}>
                    <div className={"rstMnuLeftIn fxPosWrap"}>
                        <div style={{ overflow: "hidden" }}>
                            <div className={"bold"}>פרטי המסעדה:</div>
                            <br />
                            <a href={`tel:${props.restPage.buissPhone}`} className={"overFlHid"}>
                                <div className={"phoneLine"}>
                                    <div className="clickPhoneBtn">{props.restPage.buissPhone}</div>
                                </div>
                            </a>
                            {(props.restPage.buissAddress) ? <AddressLine address={props.restPage.buissAddress} /> : ""}
                            <DayOpeningHours item={props.restPage} />
                            <BuisRating rating={props.restPage.buissRating} />
                            {/* <div className={"opDot"}>
                            <div className={`specDot ${!props.doDeliver ? "specDotClose" : ""}`}><FiberManualRecord /></div>
                            <span className={"goRight bold"}>{!props.doDeliver ? "":"לא"} זמין למשלוחים</span>
                        </div>
                        <br />
                        <div className={"opDot"}>
                            <div className={`specDot ${!props.doTakeAway ? "specDotClose" : ""}`}><FiberManualRecord /></div>
                            <span className={"goRight bold"}>{!props.doTakeAway? "":"לא"} זמין לאיסוף עצמי</span>
                        </div> */}
                        </div>
                        <br />
                        <Link to={`${url}/details`}>
                            {/* <div className={"bold"} onClick={() => props.setShowHideMoreDet()}> */}
                            <div className={"bold"} >
                                <span>עוד פרטים</span>
                            </div>
                        </Link>
                        <br />
                        <div className={"detailsLine moreDetBtn pointing"}>
                            <div className={"bold"} onClick={() => props.setDispOnlyDelivery()}>
                                <span>{(props.dispOnlyDelivery) ? "הצג תפריט מלא" : "הצג תפריט משלוחים"} </span>
                            </div>
                        </div>
                    </div>
                </div> : ""
            }
        </div >
    );
}

export default RestMnu;
