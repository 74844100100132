import React from 'react';
import { Switch, Route } from 'react-router';
import Scroll from 'react-scroll';

import utilsSinglePage from "../../_Utils/utilsSinglePage";
import utilsContactPage from "../../_Utils/utilsContactPage";
import utilsCategurys from "../../_Utils/utilsCategurys";
import utilsRests from '../../_Utils/utilsRests';
import CreateRoutes from './CreateRoutes';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;


let lstSimpePages = [];
let lstRestsPages = [];
let lstContactPages = [];
let lstCategurysPages = [];

const TheRouts = async () => {
  // const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);
  // const [showbanners, setShowbanners] = useState(true);
  // const [showfotter, setShowfotter] = useState(true);
  // const [yesStrip, setYesStrip] = useState(true);

  let fullList = [];

  let getLIsts = await loadMaster();


  // const scrolUp = (scInt) => {
  //   scroll.scrollToTop(scInt);
  // }


  lstRestsPages?.forEach((item, index) => {

    let fixdurl = item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id;

    fullList.push(`/restaurants/${fixdurl}`)
  })


  fullList.push("/mbcats")

  lstSimpePages?.forEach((item, index) => {
    fullList.push(`/${item.inLinkToPage}`)
  })

  lstContactPages?.forEach((item, index) => {
    fullList.push(`/${item.inLinkToPage}`)
  })

  lstCategurysPages?.forEach((item, index) => {
    let fixdurl = item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id;

    fullList.push(`/${fixdurl}`)

  })

  fullList.push("/search");

  fullList.push("/TakeOrDel");
  fullList.push("/deliAdress");
  fullList.push("/LoginPage");
  fullList.push("/UserDetails");
  fullList.push("/MyDetails");


  fullList.push("/MyOrders");
  fullList.push("/sitemap");
  fullList.push("/err404");




  let rutesBk = CreateRoutes(fullList);
  
  return (rutesBk);

}

export default  TheRouts 





const loadMaster = async () => {

  let restPgs = await utilsRests.getRestsFull();
  lstRestsPages = restPgs;

  let pgsNames = await utilsSinglePage.allPgsNames();
  lstSimpePages = pgsNames;

  let contPNames = await utilsContactPage.allPgsNames();
  lstContactPages = contPNames;

  let categPNames = await utilsCategurys.getAllCategurys();
  lstCategurysPages = categPNames;

}







