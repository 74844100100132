import React, { useEffect, useState, useContext } from 'react';
import { Accordion, Card } from 'react-bootstrap';


import ConnUrls from '../../Connections/ConnectURL';
import { MobiOrNotContext } from '../../ContextApi/context';
import PopUpTheComp from '../../DTechComp/PopUpTheComp';
import SwipeGallery from '../../DTechComp/SwipeGallery';
import CustomDialogContent from '../../DTechComp/CustomDialogContent';

const DesktopGallery = (props) => {
    const [thePopUp, setThePopUp] = useState("");
    const [mobiOrNot] = useContext(MobiOrNotContext);


    useEffect(() => {

    }, [])


    const gallClick = (indx, e) => {

        let theGalls = Array.from(
            document.getElementsByClassName('GallBlock')
        );

        theGalls.forEach((elem, index) => {
            if (index != indx) {
                // elem.classList.remove('active-gal');
                elem.style.width = `${(30 / theGalls.length - 1)}%`;
            }
            else {
                // elem.classList.add('active-gal');
                elem.style.width = `${(80)}%`;
            }
        });

        let theSwips = Array.from(
            document.getElementsByClassName('smallGal')
        );

        theSwips.forEach((elem, index) => {
            if (index != indx) {
                elem.style.display = "none";
            }
            else { elem.style.display = "table"; }
        });

    }
    const showGall = (itm, indx) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => setThePopUp()}
                cssStyl={"gallSwipepop"}
                theInsideComp={
                    <SwipeGallery imagesList={itm.gallImages} startFrom={indx} />}
                Title={[""]}
                contentComponent={CustomDialogContent}
            />
        )
    }


    return (
        <>
            {props.item.gallerys.map((itm, indx) => {
                let stylw =
                    // (indx == 0) ?
                    //     `${(70)}%`
                    //     :
                    `${(100 / props.item.gallerys.length - 1)}%`
                // ;

                let stylh = `${window.innerHeight - (window.innerHeight * 0.20)}px`;

                // return (<SwipeSlides key={indx} imagesList={itm.gallImages} />)
                return (
                    <div className={"GallBlock pointing"} key={indx}
                        style={{
                            backgroundImage: `url(${ConnUrls.servMediasUrl}/${itm.mainImg})`,
                            width: stylw,
                            height: stylh
                        }}
                        onClick={(e) => gallClick(indx, e)}
                    >
                        <div className={"smallGal"}>
                            {itm.gallImages.map((imgs, ind) => {
                                return (
                                    <div key={ind} className={"smallGalImg pointing"} onClick={() => showGall(itm, ind)} >
                                        <img src={`${imgs.img}`} alt="" />
                                    </div>
                                )

                            })}
                        </div>
                        {/* <div className={"swipGallItm"}>
                                        <SwipeGallery key={indx} imagesList={itm.gallImages} />

                                    </div> */}
                    </div>
                )
            })
            }
            {thePopUp}
        </>


    );
}

export default DesktopGallery;