import utils from "../_Utils/utilsMaster";
import { useContext, useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { CurrentPContext, MasterContext, UserContext } from "../ContextApi/context";
import { ArrowBack, Circle, Login, Logout, MenuOpenSharp, MenuSharp } from '@mui/icons-material';
import ArrowRight from '@mui/icons-material/ArrowRight';
import { useHistory } from "react-router";
import { useSwipeable } from 'react-swipeable';
import TopBigSlides from "../DTechComp/TopBigSlides";
import ConnUrls from '../Connections/ConnectURL';
import { SectionsContext } from "../ContextApi/Sections_Context";
import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, {
  A11y, Autoplay, EffectCoverflow, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar
} from 'swiper';


const DeskMenu = (props) => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [Sections, setSections] = useContext(SectionsContext);

  const [user, setUser] = useContext(UserContext);
  const [swiper, setSwiper] = useState(null);
  const [logurl, setLogurl] = useState("");

  const [socialMds, setSocialMds] = useState([]);

  const [mnuShow, setMnuShow] = useState(false);
  const [showMyDetails, setShowMyDetails] = useState(false);

  const [topSlides, setTopSlides] = useState();
  const history = useHistory();

  useEffect(() => {
    // console.log(user)
    // setTopSlides(<TopBigSlides TopImages={master.mobiMenuTopImages} />)
    setLogurl(master.webSiteLogo)
    setSocialMds(master.socialMds)

  }, [])

  // const loginUsr = () => {
  //   history.push("/LoginPage");
  //   setMnuShow(false);
  // }
  // const logOutUsr = () => {
  //   localStorage.removeItem('lolusrtoken');
  //   setUser({});
  //   setMnuShow(false);
  //   history.push("/");
  // }


  const slideGoTo = (ind) => {
    // props.theIndx(ind)
    setMnuShow(false)
    props.goToSld(ind)
  }


  return (
    <div className="mobiMnuTop">
      <div className="onRight logoTop"
        onClick={() => { slideGoTo(0) }}>
        <img className={"mainLogo"}
          src={`${ConnUrls.servMediasUrl}/${logurl}`} alt="LOGO"
        />
      </div>
      <div className={"mobiLineHead"}>
        <div className={"mobiLineHeadTxt"}>
          {master.mobiLineHead}
        </div>
      </div>
      <div id="mobiFloatLeftID"
        onClick={() => setMnuShow(!mnuShow)}
        className={`mobiFloatLeft `}>
        <MenuSharp className={"mobiArr"} sx={{}} />
      </div>
      <div className="mobiMnuInWrap">


        <div className={`mnusWrap ${!mnuShow ? "" : "mobiFloatLShow"}`} >

          <ul className="menuStrip">
            {
              Sections.map((itm, indx) => {
                return (
                  <li key={indx} className={`pointing ${props.activeIndx == indx + 1 ? "activeMnu" : ""}`}
                    onClick={() => {
                      slideGoTo(indx);
                    }}><span>{itm.pageName}</span></li>
                )
              })
            }
          </ul>
          <div className="socialMds">
            <div className="socialMdsMobiWrap">
              {
                socialMds.map((itm, indx) => {
                  return (
                    <a key={indx} href={itm.url} target={"_blank"}>
                      <div className={`socialIco onRight pointing`} >
                        <img src={`${ConnUrls.servMediasUrl}/${itm.src}`} /></div>
                    </a>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )

}

export default DeskMenu;
