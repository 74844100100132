
import { ArrowCircleRightSharp } from '@mui/icons-material';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams, useRouteMatch } from "react-router-dom";
import Scroll from 'react-scroll';
import ConnUrls from '../../Connections/ConnectURL';
import { CurrentPContext, MasterContext, OrderContext, ScreenWidthContext } from '../../ContextApi/context';
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import myFunctions from '../../myFunctions';
import utilsContactPage from '../../_Utils/utilsContactPage';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const ContactPage = (props) => {
  const [order, setOrder] = useContext(OrderContext);
  const [master, setMaster] = useContext(MasterContext);
  const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [allRestsOfCat, setAllRestsOfCat] = useState([]);
  const [cattxtOn, setCattxtOn] = useState([true]);
  const [waitImgItm, setWaitImgItm] = useState({});

  const [erMsg, setErMsg] = useState("");

  const [fullName, setFullName] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [email, setEmail] = useState("");
  const [txtAre, setTxtAre] = useState("");
  const [subj, setSubj] = useState("");



  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();


  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }
  
  useEffect(() => {
    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(false)
    getSinglePage();
    setLoading(false);

  }, [])


  const getSinglePage = async () => {
    let theSingle = await utilsContactPage.getPageByName(props.pageName);
    setSubj(theSingle.subj)

    setCurrentPage({ ...theSingle, pageTYp: "" });

  }
  const goToBack = () => {
    sessionStorage.setItem("ContactPage", "");
    history.goBack();
  }

  const sendMsg = async () => {
    let validatoring = "";

    validatoring = (fullName != "" && fullName.length > 2) ? "" : "<p>יש להזין שם מלא</p>";
    validatoring += myFunctions.validPhone(phoneNum);
    // validatoring += myFunctions.validEmail(email);
    validatoring += (txtAre != "") ? "" : "<p>יש להזין תוכן הודעה</p>";
    if (validatoring == "") {

      let msgg = {
        fullName, phoneNum, email, txtAre, subj
      }
      let sndMsg = await utilsContactPage.sendMsg(msgg);
      
      setErMsg(sndMsg.msg)
      setFullName("");
      setPhoneNum("");
      setEmail("");
      setTxtAre("");
    }
    else {
      setErMsg(validatoring)
    }
  }

  return (
    <div className="ContactPage">
      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(screenWidth > 800) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>
      </div>

      {loading ? "" :
        <>
          {(screenWidth <= 800) ?
            <>
              <div className={"headOnFixC SecondFont bold big"}>
                <div className={`backSd`}>
                  <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>


              </div>
              <div className={`singlePagePad`}>
              </div>
            </>
            :
            ""
          }
          {(currentPage.mainImage != null && currentPage.mainImage != "") ?
            <div className={"singlPImg"}>
              <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage}`} alt={currentPage.altTags || currentPage.pageName || ""}/>
            </div>
            : ""}
          <div className={"spaceRightLong centerText spacePadBig anakMeod bold SecondFontRegular lineHSmall "}>
            {currentPage.mainTitle ? currentPage.mainTitle : ""}</div>

          <div className={"simplePSides overflowHid"}>
            <div className={"ContactPageContent centerText MyFont big"}>
              {parse(`${currentPage.mainContent}`)}
            </div>
            <div className={"ContCall"}>
              {currentPage.addressLine != "" ? <div className={"contxt"}>כתובת: {currentPage.addressLine}</div> : ""}
              {currentPage.phoneNum != "" ? <div className={"contxt"}>טלפון: <a href={`tel:${currentPage.phoneNum}`}>{currentPage.phoneNum}</a></div> : ""}
              {currentPage.fax != "" ? <div className={"contxt"}>פקס: {currentPage.fax}</div> : ""}
            </div>
          </div>



          <div className={"ContactsData"}>
            <div className={"contWrap"}>
              <label className={"verySmall fullRow"}>שם מלא</label>
              <input className={"textRight fullRow"} value={fullName} onChange={(e) => setFullName(e.target.value)} label="שם מלא" variant="filled" />
            </div>
            <div className={"contWrap"}>
              <label className={"verySmall fullRow"}>טלפון</label>
              <input className={"fullRow"} value={phoneNum} onChange={(e) => setPhoneNum(e.target.value)} label="טלפון" variant="filled" />
            </div>
            <div className={"contWrap"}>
              <label className={"verySmall fullRow"}>מייל</label>
              <input className={"fullRow"} value={email} onChange={(e) => setEmail(e.target.value)} label="מייל" variant="filled" />
            </div>
            <div className={"contWrap"}>
              <label className={"verySmall fullRow"}>טקסט חופשי</label>
              <textarea className={"fullRow"} value={txtAre} onChange={(e) => setTxtAre(e.target.value)} label="טקסט חופשי" variant="filled" ></textarea>
            </div>
            <div className={"contWrap"}>
              <button className={"contactGo fullRow"} value="שלח" onClick={() => sendMsg()} >שלח</button>
            </div>

            <div className={"fullRow errorLine"}>
              <p>{parse(erMsg)}</p>
            </div>

          </div>



          {/* <Prompt
            message={(location, action) => {
              sessionStorage.setItem("scrol", document.scrollY)
              return true;
            }}
          /> */}
          <ScrollingSaver pagNam={"ContactPage"} />
        </>

      }
    </div >
  );
}

export default ContactPage;
