import React, { useEffect, useState, useRef, useContext } from 'react';
import { MobiOrNotContext } from '../../ContextApi/context';

const DropHouresSelection = (props) => {
  const [mobiOrNot] = useContext(MobiOrNotContext);

  const [isOpen, setIsOpen] = useState(false);
  const [numberOptions, setNumberOptions] = useState([]);
  const dropdownRef = useRef(null);

  useEffect(() => {
    getTimesList();
  }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  // Calculate the number of quarter-hour increments
  const getTimesList = () => {
    let timeSlots = [];
    let strttm = props.fromHour;
    let endttm = props.toHour - 1;
    for (let hour = strttm; hour <= endttm; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const formattedHour = hour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        timeSlots.push(`${formattedHour}:${formattedMinute}`);
      }
    }

    setNumberOptions(timeSlots);
  };

  return (
    <div style={props.wrpaStyl}>
      <input
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
        readOnly
        type='text'
        className={"inputsStyle pointing"}
        value={`${props.lbl} ${props.Val}`}
      />
      <div
        className={`${props.drpArrCss} pointing`}
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
        style={{ backgroundImage: `url("/PermanentImages/drpArrowWh.png")` }}
      ></div>

      <div ref={dropdownRef}>
        {(mobiOrNot) ?
          (isOpen) ?
            <div className='mobiDropSelectonOpts'>
               <p style={{position:'fixed'}}>לאיזה שעה?</p>
              {
                numberOptions.map((time) => (
                  <div className='fullRow' onClick={(e) => {
                    props.settingFunc(time);
                    setIsOpen(false);
                  }} key={time} value={time}>
                    {time}
                  </div>
                ))
              }
            </div> : ""
          :
          (isOpen) ?
            <div style={props.selectionstyl}>
              <select
                className={props.Css}
                value={props.Val}
                onChange={(e) => {
                  props.settingFunc(e.target.value);
                  setIsOpen(false);
                }}
                onBlur={() => setIsOpen(false)}
                size={Math.min(numberOptions.length, 10)}
              >
                {numberOptions.map((time) => (
                  <option key={time} value={time}>
                    {time}
                  </option>
                ))}
              </select>
            </div>
            : ""
        }
      </div>
    </div>
  );
};

export default DropHouresSelection;
