import React, { useContext, useEffect, useState } from 'react'
import SectionContact from './SectionContact';
import SectionGallery from './SectionGallery';
import SectionHead2Btns from './SectionHead2Btns';
import SectionTextBlock from './SectionTextBlock';
import SectionRestMenu from './SectionRestMenu';

const SectionItem = (props) => {


    const getSecTypFuncion = {
        "1": <SectionTextBlock item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />,
        "2": <SectionHead2Btns item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />,
        "3": <SectionGallery item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />,
        "4": <SectionContact item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />,
        "5": <SectionRestMenu item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />
    }
    return getSecTypFuncion[props.item.sectiontype] || <SectionTextBlock item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />

    // switch (props.item.sectiontype) {
    //     case "1":
    //         {
    //             return <SectionTextBlock item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />
    //         }
    //     case "2":
    //         {
    //             return <SectionHead2Btns item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />
    //         }
    //     case "3":
    //         {
    //             return <SectionGallery item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />
    //         }
    //     case "4":
    //         {
    //             return <SectionContact item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />
    //         }
    //     case "5":
    //         {
    //             return <SectionRestMenu item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />
    //         }
    //     default:
    //         {
    //             return <SectionTextBlock item={props.item} showEffects={props.activeIndx - 1 == props.itemIndx} />

    //         }
    // }


}

export default SectionItem;