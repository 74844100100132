import React, { useContext, useEffect, useState } from 'react'
import parse from 'html-react-parser';
import PopUpTheComp from '../../../DTechComp/PopUpTheComp';
import OrderPlaceOnRest from '../../../DTechComp/OrderPlaceOnRest';
import { MasterContext } from '../../../ContextApi/context';
import CustomDialogContent from '../../../DTechComp/CustomDialogContent';

const SectionHead2Btns = (props) => {
    const [master, setMaster] = useContext(MasterContext);

    const [thePopUp, setThePopUp] = useState("");



    useEffect(() => {
    }, [])

    const leftBtnClicked = () => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"whiteBack"}
                theInsideComp={
                    <OrderPlaceOnRest closePop={() => { setThePopUp() }} />}
                Title={[`${master.tablOrdsHead}`]}
                theTitlePopCss={"titleBig"}
                contentComponent={CustomDialogContent}
            // cancelClick={() => { setThePopUp() }}
            />

        )

    }


    return (
        <>
            <div className={"sectionBlock"} style={{

                left: props.item.blockLeft + "%",
                top: props.item.blockTop + "%",
                height: (parseInt(props.item.blockHeight) + 10) + "%",
                width: props.item.blockWidth + "%",


            }}>

                <div className={"sectionBack"} style={{
                    backgroundColor: props.item.blockColor,
                    opacity: props.item.blockTransparent
                }}>
                </div>
                <div className={"IN_sectionBlock"}>
                    <div className={`mainHead ${props.showEffects ? `animate__animated animate__${props.item.mainHeadEffect || ""}` : ""}`}
                        style={{ color: props.item.mainHeadColor || "white" }} >
                        {props.item.mainHead}
                    </div>
                    <div className={`smalltxt ${props.showEffects ? `animate__animated animate__${props.item.smalltxtEffect || ""}` : ""}`}
                        style={{ color: props.item.smalltxtColor || "white" }} >
                        {parse(props.item.smalltxt)}
                    </div>
                    <div className={"blocksBtns"}>
                        {/* <div className={`btnRight onRight ${props.showEffects ? `animate__animated animate__${props.item.btn1Effect || ""}` : ""}
                         ${props.item.btn1delay ? ` ${props.item.btn1delay || ""}` : ""}
                         ${props.item.btn1duration ? ` duration${props.item.btn1duration || ""}` : ""}`}
                        >
                            <a href={props.item.btn1Lnk}>
                                <button className={"pointing iamBtn"}
                                    style={{ backgroundColor: props.item.btn1Color, color: props.item.btn1ForeColor }}
                                    onClick={() => { }} >
                                    {props.item.btn1Txt}</button>
                            </a>
                        </div> */}

                        {/* <div className={`btnLeft onLeft  */}
                        {props.item.btn2Txt !== "" &&
                            <div className={`btnLeft 
                        ${props.showEffects ? `animate__animated animate__${props.item.btn2Effect || ""}` : ""}
                        ${props.item.btn2delay ? ` ${props.item.btn2delay || ""}` : ""}
                        ${props.item.btn2delay ? ` duration${props.item.btn2duration || ""}` : ""}`}>

                                <button className={"pointing iamBtn"}
                                    style={{ backgroundColor: props.item.btn2Color, color: props.item.btn2ForeColor }}
                                    onClick={
                                        () => leftBtnClicked()
                                    }
                                >
                                    {props.item.btn2Txt}</button>
                            </div>
                        }
                    </div>

                </div>
            </div>

            {thePopUp}
        </>


    );
}

export default SectionHead2Btns;