import { useState, createContext, useEffect, useReducer } from 'react';
import myFunctions from '../myFunctions';
import masterUtil from "../_Utils/utilsMaster";


import { io } from 'socket.io-client';
import ConnUrls from '../Connections/ConnectURL';

export const UserContext = createContext();

export const UserContextProvider = props => {
    const [user, setUser] = useState();

    useEffect(async () => {

        // let bk = await checkTok();
        let bk = await myFunctions.connCheck();
        if (bk != null) {
            // console.log("conncheck")
            // console.log(bk.user)
            setUser(bk.user);
        }
        else {

            let user = {
                firstName: "",
                lastName: "",
                email: "",
                rest: "",
            }
            setUser(user)
        }

        // let logedUser =  myFunctions.connCheck();
        // // JSON.parse(sessionStorage.getItem("curUser"));
        // if (logedUser != null) setUser(logedUser);

    }, [])

    useEffect(() => {

        if (user?.token) {
            localStorage.setItem("lolusrtoken", user.token);
        }
    }, [user])

    return (
        <UserContext.Provider value={[user, setUser]}>
            {props.children}
        </UserContext.Provider>
    )
}




export const MasterContext = createContext();

export const MasterContextProvider = props => {
    const [master, setMaster] = useState();


    return (
        <MasterContext.Provider value={[master, setMaster]}>
            {props.children}
        </MasterContext.Provider>
    )
}

export const ScreenWidthContext = createContext();

export const ScreenWidthContextProvider = props => {
    const [screenWidth, setScreenWidth] = useState();

    useEffect(() => {
        handleResize()
    }, [])


    const handleResize = () => {
        //  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)
        setScreenWidth(window.screen.width != null ? window.screen.width : "")
    }

    window.addEventListener('resize', handleResize)

    return (
        <ScreenWidthContext.Provider value={[screenWidth, setScreenWidth]}>
            {props.children}
        </ScreenWidthContext.Provider>
    )
}



export const ScreenSizeContext = createContext();

export const ScreenSizeContextProvider = props => {
    const [screenSize, setScreenSize] = useState();

    useEffect(() => {
        handleResize()
    }, [])


    const handleResize = () => {
        //  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

        setScreenSize({
            width: window.innerWidth != null ? window.innerWidth : "",
            height: window.innerHeight != null ? window.innerHeight : ""
        })
    }

    window.addEventListener('resize', handleResize)

    return (
        <ScreenSizeContext.Provider value={[screenSize, setScreenSize]}>
            {props.children}
        </ScreenSizeContext.Provider>
    )
}

export const MobiOrNotContext = createContext();

export const MobiOrNotContextProvider = props => {
    const [mobiOrNot, setMobiOrNot] = useState(false);

    useEffect(() => {
        handleResizeMobi()
    }, [])


    const handleResizeMobi = () => {
        //  console.log('resized to: ', window.innerWidth, 'x', window.innerHeight)

        if (window.innerWidth != null) {
            return (window.innerWidth <= 800) ? setMobiOrNot(true) :
                setMobiOrNot(false)
        }
        else { setMobiOrNot(false) }

    }

    window.addEventListener('resize', handleResizeMobi)

    return (
        <MobiOrNotContext.Provider value={[mobiOrNot, setMobiOrNot]}>
            {props.children}
        </MobiOrNotContext.Provider>
    )
}


export const CurrentPContext = createContext();

export const CurrentPContextProvider = props => {
    const [currentPage, setCurrentPage] = useState([]);

    return (
        <CurrentPContext.Provider value={[currentPage, setCurrentPage]}>
            {props.children}
        </CurrentPContext.Provider>
    )
}



export const OrdStageContext = createContext();

export const OrdStageContextProvider = props => {

    const [ordStage, setOrdStage] = useState(0);

    useEffect(() => {
        // alert("stateCHanged: " + ordStage)
    })

    return (
        <OrdStageContext.Provider value={[ordStage, setOrdStage]}>
            {props.children}
        </OrdStageContext.Provider>
    )
}


export const OrderContext = createContext();

export const OrderContextProvider = props => {

    // let localCart = sessionStorage.getItem("am_order");
    const [order, setOrder] = useState({ restId: "", currentP: [], Orders: [] });



    const setOrd = () => {
        let stringOrd = JSON.stringify({ ...order });
        sessionStorage.setItem("am_order", stringOrd)
    }

    useEffect(() => {

        if (sessionStorage.am_order != null) {
            let localCart = JSON.parse(sessionStorage.am_order);
            setOrder(localCart)
        }

    }, [])


    useEffect(() => {
        setOrd();

    }, [order])

    return (
        <OrderContext.Provider value={[order, setOrder]}>
            {props.children}
        </OrderContext.Provider>
    )
}

