import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const createPageSection = async () => {

    let dFromF = await axios.put(ConnUrls.ApiUrl + "PageSec/sp/cr");
    return (dFromF);
}

const deletePageSection = async (id, obj) => {
    let dFromF = await axios.delete(ConnUrls.ApiUrl + "PageSec/sp/" + id, obj);
    return (dFromF);
}
const allPgsSects = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "PageSec/sp/allPgsSects", { token });

    return (dFromF);
}

const allPgsSectionsFull = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "PageSec/sp/allPgsSectionsFull", { token });

    return (dFromF);
}


const allConpagsNames = async (token) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "PageSec/sp/allConpagsNames", { token });

    return (dFromF);
}



const getPageSectionById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "PageSec/" + id);
    return (dFromF);
}

const updateSingle = async (id, obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "PageSec/" + id, obj);
    return (dFromF);
}


const getSectionByName = async (name, obj) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "PageSec/bn/" + name, obj);
    return (dFromF);
}

export default {
    getPageSectionById,
    allPgsSectionsFull,
    updateSingle,
    getSectionByName,
    createPageSection,
    allPgsSects,
    deletePageSection,
    allConpagsNames

}; 