import {
  CurrentPContextProvider, OrderContextProvider,
  MasterContextProvider, OrdStageContextProvider,
  UserContextProvider, ScreenWidthContextProvider, ScreenSizeContextProvider, MobiOrNotContextProvider
} from './ContextApi/context';
import Master from './Master/Master';
import MasterHead from "./Master//MasterHeads";
import { Route, Switch } from 'react-router';
import Sitemap from './web_pages/SiteMap/SiteMap';
import { SectionsContextProvider } from './ContextApi/Sections_Context';


function App() {
  return (
    <ScreenSizeContextProvider>
      <SectionsContextProvider>
        <ScreenWidthContextProvider>
        <MobiOrNotContextProvider>
          <MasterContextProvider>
            <UserContextProvider>
              <OrderContextProvider>
                <CurrentPContextProvider>
                  <OrdStageContextProvider>
                    <Switch>
                      <Route path={"/sitemap"}>
                        <Sitemap />
                      </Route>
                      <div className="webMain">
                        <MasterHead />

                        <Master />

                      </div>
                    </Switch>


                  </OrdStageContextProvider>

                </CurrentPContextProvider>
              </OrderContextProvider>
            </UserContextProvider>
          </MasterContextProvider >
          </MobiOrNotContextProvider>
        </ScreenWidthContextProvider >
      </SectionsContextProvider>
    </ScreenSizeContextProvider >

  );
}

export default App;

