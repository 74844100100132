import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const createTableOrder = async (theParms) => {
    // console.log(theParms)
    let dFromF = await axios.put(ConnUrls.ApiUrl + "ordTbl/orderTableByClient",{ theParms, token: null });

    return (dFromF);


}

export default {
    createTableOrder

};