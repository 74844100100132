import { ArrowCircleDownSharp, ArrowCircleRightSharp, ArrowCircleUp } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { Prompt, Route } from "react-router";
import { OrderContext, TempOrderContext } from "../../../ContextApi/context";
import OrderChkSel from "./OrderChkSel";
import StepperList from "../../../DTechComp/StepperList";
import { toBeEmptyDOMElement } from "@testing-library/jest-dom/dist/matchers";

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import OrderFunctions from "../../../OrderSettings/OrderFunctions";

const ProductOptions = (props) => {

    const [order, setOrder] = useContext(OrderContext);
    const [prodOptions, setProdOptions] = useState();
    const [prodSelect, setProdSelect] = useState();
    const [fullRowtxt, setFullRowtxt] = useState([]);

    let specInOrder = [];

    const [tempOps, setTempOps] = useState([...props.product.prodOptions]);


    let orderOptsRows = [];


    useEffect(() => {
        ///בודק שאין כבר הזמנה פתוחה, במידה ויש , פותח את המוצרים המוזמנים
        if (props.imInOrder) {
            let theord = order.Orders[props.inOrderIndex];
            if (theord != null) {
                getSetedOrder();
            }
        }

        if (props.PopMeUp && tempOps.length != 0) {
            prodOptionsChksSelect();
        }

        setSelectionHeader();

    }, [])

    useEffect(() => {

        if (props.PopMeUp && tempOps.length != 0) {
            prodOptionsChksSelect();
        }
    }, [props.PopMeUp])


    useEffect(() => {
        setOpts();
        updateTheOrd();
        setSelectionHeader();

    }, [tempOps])


    useEffect(() => {

    }, [fullRowtxt])


    const getSetedOrder = () => {

        let count = 0;
        tempOps.forEach(ops => {
            order.Orders[props.inOrderIndex].specials.forEach(ord => {
                if (ops.optionName == ord.optionName) {
                    tempOps[count].selected = ord.spec;
                }
            });
            count++;
        });

    }

    ///עדכון ההזמנה
    const updateTheOrd = () => {

        let orderOptsRows = [];
        tempOps.forEach((el) => {

            // if (el.allowMulti) {
            if (el.selected != "" && el.selected != null) {
                let spec = [];

                el.selected.forEach(elem => {
                    if (el.splitSelection) {
                        spec.push({
                            servType: elem.servType,
                            addToPrice: elem.addToPrice,
                            splitSelected: elem.splitSelected,
                            itmsMount: elem.itmsMount,
                            withSauces: elem.withSauces
                        });
                    }
                    else {
                        spec.push({
                            servType: elem.servType,
                            addToPrice: elem.addToPrice,
                            itmsMount: elem.itmsMount,
                            withSauces: elem.withSauces

                        });
                    }

                });

                orderOptsRows.push({ optionName: el.optionName, spec: spec })
            }

        });


        let theItm = order.Orders.findIndex(itm => itm.ordProdID == props.itemId);
        if (theItm != -1) {

            let tempAddsPrice = 0;
            orderOptsRows.map((item, index) => {
                item.spec.map((prc, ind) => {
                    tempAddsPrice = parseInt(tempAddsPrice) + (parseInt(prc.addToPrice != "" ? prc.addToPrice : 0) * parseInt(prc.itmsMount));
                })
            })

            order.Orders[theItm].addsPrice = tempAddsPrice;
            order.Orders[theItm].specials = orderOptsRows;
            setOrder({ ...order })
        }
        else {
            props.tempOrderProds(orderOptsRows);
        }

    }


    const setSelectionHeader = () => {

        let fROwBk = [];
        let count = 0;


        if (order.Orders[props.inOrderIndex] != null) {

            tempOps.forEach(ops => {
                order.Orders[props.inOrderIndex].specials.forEach(ord => {
                    if (ops.optionName == ord.optionName) {

                        fROwBk.push(`<span class="optHeader bold">${ord.optionName}<span/><br/>`);
                        for (let i = 0; i < ord.spec.length; i++) {
                            fROwBk.push(`<span class="headORdTxt">${(ord.spec[i].itmsMount != 0) ?
                                `` : ""}
                            ${(OrderFunctions.pizzConten(ord.spec[i]).props.children)} ${ord.spec[i].servType}
                            <span/><br/>`);
                        }
                    }
                });
                count++;
            });


            setFullRowtxt(fROwBk);
        }
    }

    //מסדר את אופציות המנה
    const setOpts = () => {



        let allOpts = tempOps.map((item, index) => {

            return (
                <div key={index} className={"prodOrderLine"}>
                    <div className={"seType"}>
                        <div className={"prodLineHead"}>{item.optionName}</div>
                    </div>
                    {/* {item.allowMulti ? setAsMulti(item, index) : setAsOne(item, index)} */}
                    {setAsMulti(item, index)}
                </div>
            )
        })
        // setProdOptions(allOpts);


    }




    //מסדר את עמוד הבחירה עם צ'ק בוקסים
    const setAsMulti = (item, index) => {

        let onBtn = (item.selected != "") ?
            "בחרתי " + item.selected.length :
            (item.optionBtnTx == "" || item.optionBtnTx == null) ? "בחירה" : item.optionBtnTxt;

        let bk =
            <div className={"addToOrderBtn pointing"}
                onClick={() => prodOptionsChksSelect(item, index)}>
                {onBtn}
            </div>

        return (bk)

    }


    let rollDown = "";

    const rollMe = () => {

        let inProdTxt = window.document.getElementsByClassName("rollItm");

        if (inProdTxt.length > 0) {

            rollDown = ((rollDown == "") ? "rollDown" : "");
            inProdTxt[0].className = "rollItm inProdTxt " + rollDown;
        }
    }

    let conyt = 0;
    //יוצר עמוד בחירות צ'ק בוקסס
    const prodOptionsChksSelect = (itemId) => {

        //מעדכן קודם את הרשימה במידה ומדובר במוצר שהוזמן
        if (itemId != null) {
            let tempo = order.Orders.findIndex(ord => ord.ordProdID == itemId)

            if (tempo != -1) {
                order.Orders[tempo].specials.forEach((specitm) => {
                    let theOp = tempOps.findIndex(it => it.optionName == specitm.optionName)
                    if (theOp != -1) {
                        tempOps[theOp].selected = specitm.spec
                    }
                })
            }

        }


        let allOpts = tempOps.map((item, index) => {
            conyt++;

            return (
                <OrderChkSel key={index} itm={item} indx={index}
                    ContCallBack={(cheksBack) => ChksSelected(cheksBack, index)} />
            )
        })

        setProdSelect(
            <div className={"prodOptionsSelect"} id={"prodSel"}>
                <div className={"prodOptionsSelectWrap"}>
                    <div className={"headOnFixC SecondFont bold big"}>
                        {props.product.prodName}

                        <div className={"mnuDivPrdcReg prodSText"}>
                            <div className={`rollItm FirstFont inProdTxt smallFont ${rollDown}`} onClick={() => rollMe()} >
                                {props.product.prodProducts}
                            </div>
                            <div className={"upDownArrow"}>
                            </div>
                        </div>

                        <div className={`backSd`}>
                            <ArrowCircleRightSharp onClick={() => setProdSelect()} />
                        </div>
                    </div>
                    <div className={"OptionsSelectWrap"} >
                        <div className={"OptionsSelectTheTops"} >
                            <StepperList List={tempOps} />
                            {allOpts}
                        </div>
                    </div>
                    <div className={"optionsBtnWrap"}>
                        <div className={"selectOpBtnWrap"}>
                            <div className={"selectOpBtn selectOpBtnSmall"}
                                onClick={() => setProdSelect()}>
                                המשך
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )

    }


    //ContCallBack
    //נבחרו בחירות בעמוד בחירות של צק בוקסס
    const ChksSelected = (chekBxs, btnIndex) => {


        let tempOp = [...tempOps];
        let allChks = [];

        if (chekBxs.length > 0) {

            chekBxs.forEach(chk => {
                allChks.push(chk)
            });
            tempOp[btnIndex].selected = allChks;
        }
        else {
            tempOp[btnIndex].selected = "";
        }


        
        let ttt = [];
        if (tempOp != "") {
            ttt = tempOp.map((opt, indx) => {
                let bkMp = "";
                if (opt.selected) {
                    let tottalSelects = 0;
                    bkMp = opt.selected.map(selectedItm => {
                        tottalSelects = (selectedItm.splitSelected != 'full') ?
                            tottalSelects + 0.5 : tottalSelects + 1;

                        selectedItm.addToPrice = (parseFloat(opt.freeAdds) > parseFloat(tottalSelects)) ?
                            "" : selectedItm.addToPrice;
                        return selectedItm;
                    })

                }
                return (bkMp)
            })

        }

        setTempOps(tempOp);
    }

    return (
        <div className={"prodOptions"}>
            <div key={"ff14"} className="prodOrderLine ">

                <div className={"seType"}>
                    <div className={"prodLineHead headORdLine"}>
                        <span className={"Heads smallFont"}>{(fullRowtxt != "") ? "מה הזמנתי:" : ""}</span>
                        {
                            fullRowtxt.map((itm, inx) => {
                                return (
                                    <span className={"smallFont"} key={inx}>{ReactHtmlParser(itm)}</span>
                                )
                            })
                        }
                    </div>
                </div>

                <div className={"addToOrderBtn pointing"}
                    onClick={() => prodOptionsChksSelect(props.itemId)}>
                    {"לחץ לשינויים"}

                </div>
            </div>

            {prodOptions}
            {prodSelect}

            {/* <Prompt
                message={(location, action) => {
                    if (prodSelect != null) {
                        setProdSelect();
                        return false;
                    }
                }}
            /> */}
        </div >
    );
}

export default ProductOptions;
