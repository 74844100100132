import { useContext, useEffect, useState } from "react";
import { CurrentPContext, MasterContext, MobiOrNotContext, OrderContext, OrdStageContext, ScreenWidthContext } from "../ContextApi/context";
import { ArrowCircleDownSharp, ArrowCircleUp, ArrowBackIosNew, ArrowCircleRightSharp, Height, QrCodeScannerOutlined } from '@mui/icons-material';

import { Prompt, useHistory } from "react-router";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import myFunctions from "../myFunctions";
import parse from 'html-react-parser';
import utilsTablesOrders from "../_Utils/utilsTablesOrders";
import DropNumbersSelection from "./OrderTableItems/DropNumbersSelection";
import DatePick from "./OrderTableItems/DatePick";
import DropHouresSelection from "./OrderTableItems/DropHouresSelection";
import TableOrder_Confirmd from "./Pop_Fixed/TableOrder_Confirmd";

const OrderPlaceOnRest = (props) => {
    const history = useHistory();
    const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);

    const [clientName, setClientName] = useState("")
    const [clientPhone, setClientPhone] = useState("")
    const [remarks, setRemarks] = useState("")
    const [dinners, setDinners] = useState(2)
    const [dateToCome, setDateToCome] = useState(new Date())
    const [timeToCome, setTimeToCome] = useState("")
    const [showOrdBtn, setShowOrdBtn] = useState(true)
    const [sending, setSending] = useState(false)
    const [erMsg, setErMsg] = useState("");
    const [thePopUp, setThePopUp] = useState("");

    const [hoursList, setHoursList] = useState([])

    useEffect(() => {
        getHoursList();
    }, [])


    const getHoursList = () => {
        let bk = [];
        for (let hour = 11; hour <= 22; hour++) {
            for (let minute = 0; minute < 60; minute += 30) {
                let hTxt = (hour < 10 ? "0" + hour : hour) + ":" + (minute === 0 ? "00" : minute);
                bk.push(hTxt);
            }
        }
        setTimeToCome(bk[0])
        setHoursList(bk);
    }

    const handleDateChange = (date) => {
        setDateToCome(date);

    };


    const orderMeTable = async () => {
        let errMsg = "";

        if (clientName != "") {
        }
        else {
            errMsg = "יש להזין שם מלא"
        }

        if (errMsg == "") {
            if (clientPhone != "") {
                let chk = myFunctions.validPhone(clientPhone);
                if (chk == "") {
                }
                else {
                    errMsg = "יש להזין מספר טלפון תקין"
                }
            }
            else {
                errMsg = "יש להזין מספר טלפון תקין"
            }
        }

        if (errMsg === "") {
            const selectedDateTime = new Date(dateToCome);
            const selectedHourTime = timeToCome.split(":");
            selectedDateTime.setHours(parseInt(selectedHourTime[0], 10)); // Convert string to integer
            selectedDateTime.setMinutes(parseInt(selectedHourTime[1], 10)); // Convert string to integer

            let SelTime = new Date(selectedDateTime)
            const currentDateTime = new Date();

            if (SelTime < currentDateTime) {
                errMsg = "לא ניתן להזמין לזמן שעבר כבר";
            }
        }


        //הכל תקין שולח הזמנה
        if (errMsg == "") {
            setSending(true)
            let theParms = {
                clientName, clientPhone, dinners, timeToCome,
                dateToCome,
                remarks
            }
            let sndMsg = await utilsTablesOrders.createTableOrder(theParms);

            if (sndMsg.msg == "approved") {
                setThePopUp(<TableOrder_Confirmd
                    TblorderNumber={sndMsg.orderNumber}
                    okClick={() => props.closePop()} />)
            }
            else {
                setErMsg("לא הצלחנו לבצע הזמנה, נסה שוב")
                setSending(false)
            }

            // let erMsg = (sndMsg.msg == "approved") ?
            //     "ההזמנה נשלחה, מספר הזמנה: " + sndMsg.orderNumber + " אנו נחזור אלייך לאישור"
            //     : "לא הצלחנו לבצע הזמנה, נסה שוב";

            // setErMsg(erMsg)
            // setSending(false)
        }
        else {
            setErMsg(errMsg)
        }
    }


    const wrpaStyl = (!mobiOrNot) ?
        { position: 'relative', float: 'right', width: '30%' } :
        { position: 'relative', width: '100%' };

    const DtPickwrpaStyl = (!mobiOrNot) ?
        { position: 'relative', float: 'right', width: '130%' } :
        { position: 'relative', width: '100%' };

    const selectionstyl =
        { position: 'fixed', zIndex: 1 };

    const wrpaStylwithSpaceRight = (!mobiOrNot) ?
        { position: 'relative', float: 'right', right: '19px' } :
        { position: 'relative' };


    return (
        <div className={"orderPlaceBlock"}>
            <div className={"orderPlaceBlockWrap"}>
                    <DropNumbersSelection
                        drpArrCss={"drpArrowWhite"}
                        wrpaStyl={wrpaStyl}
                        selectionstyl={selectionstyl}
                        lbl="אנשים"
                        Typ="text"
                        Css="inputsDropDownStyle"
                        Val={dinners}
                        placeholder="מספר סועדים"
                        settingFunc={(val) => setDinners(val)}
                        maxNumb={50}
                    />

                    <DatePick
                        drpArrCss={"drpArrowWhite"}
                        wrpaStyl={DtPickwrpaStyl}
                        lbl="ל"
                        settingFunc={(val) => setDateToCome(val)}
                        Css="inputsStyle pointing"
                        selectedDate={dateToCome}

                    />

                    <DropHouresSelection
                        drpArrCss={"drpArrowWhite"}
                        wrpaStyl={wrpaStylwithSpaceRight}
                        selectionstyl={selectionstyl}
                        lbl="לשעה"
                        Typ="text"
                        Css="inputsDropDownStyle"
                        Val={timeToCome}
                        placeholder="לשעה"
                        settingFunc={(val) => setTimeToCome(val)}
                        fromHour={10}
                        toHour={23}
                    />
            </div>
            <div className={"orderPlaceBlockWrap ord2"}>
                <div className={(!mobiOrNot) ? "onRight txConInput" : ""}>
                    <input type={"text"}
                        className={"contactInput"}
                        value={clientName}
                        placeholder={"שם מלא"}
                        onChange={(e) => { setClientName(e.target.value) }} />

                </div>
                <div className={(!mobiOrNot) ? "onLeft smallRIght txConInput" : ""}
                >

                    <input type={"text"}
                        className={"contactInput"}
                        value={clientPhone}
                        placeholder={"טלפון"}
                        onChange={(e) => { setClientPhone(e.target.value) }} />
                </div>
            </div>
            <div className={"orderPlaceBlockWrap ord2"}>
                <div>
                    <textarea
                        className={"remarksInput"}
                        value={remarks}
                        placeholder={"הערות"}
                        onChange={(e) => { setRemarks(e.target.value) }} >
                    </textarea>
                </div>
            </div>

            <div className={"fullRow overflowHid"}>
                <div className={"erMsg fullRow"}>
                    <p style={{ minHeight: '20px' }} className={"pMssge"}>{parse(erMsg)}</p></div>
            </div>


            {
                (!sending) ?
                    <div className={"btnsPopWrap spaceMargTopVBig"}>
                        {
                            <button type="button" className="btnPopRegular pointing"
                                onClick={() => { (!showOrdBtn) ? props.closePop() : orderMeTable() }}>
                                {(!showOrdBtn) ? "סגור" : (props.BtnTxt != null) ? props.BtnTxt : "מצאו לי שולחן"}</button>
                        }
                    </div> :
                    <div className={"erMsg"}>שולח...</div>
            }

            {thePopUp}
        </div>
    );
}

export default OrderPlaceOnRest;
