import React, { useEffect, useState, useRef, useContext } from 'react';
import { MobiOrNotContext } from '../../ContextApi/context';

const DropNumbersSelection = (props) => {
  const [mobiOrNot] = useContext(MobiOrNotContext);

  const [isOpen, setIsOpen] = useState(false);
  const numberOptions = Array.from({ length: props.maxNumb }, (_, index) => index + 1);
  const dropdownRef = useRef(null);

  useEffect(() => {
    // Add event listener to the document to detect clicks outside the dropdown
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const handleInputClick = () => {

    setIsOpen(!isOpen);
  };

  return (
    <div style={props.wrpaStyl}>
      <input
        onClick={handleInputClick}
        readOnly
        type='text'
        className={"inputsStyle pointing"}
        value={`${props.Val} ${props.lbl}`}
      />
      <div
        className={`${props.drpArrCss} pointing`}
        onClick={handleInputClick}
        style={{ backgroundImage: `url("/PermanentImages/drpArrowWh.png")` }}
      ></div>

      <div ref={dropdownRef}>
        {(mobiOrNot) ?
          (isOpen) ?
            <div className='mobiDropSelectonOpts'>

              <p style={{position:'fixed'}}>כמה אנשים?</p>
              {
                numberOptions.map((time) => (
                  <div className='fullRow' onClick={(e) => {
                    props.settingFunc(time);
                    setIsOpen(false);
                  }}>
                    {time}
                  </div>
                ))
              }
            </div> : ""
          :
          (isOpen) ?
            <div style={props.selectionstyl}>
              <select
                className={props.Css}
                value={props.Val}
                onChange={(e) => {
                  props.settingFunc(parseInt(e.target.value));
                  setIsOpen(false);
                }}
                size={Math.min(numberOptions.length, 10)}
              >
                {numberOptions.map((num) => (
                  <option key={num} value={num}>
                    {num}
                  </option>
                ))}
              </select>
            </div>
            : ""
        }
      </div>
    </div>
  );
};

export default DropNumbersSelection;
