import masterUtil from "../_Utils/utilsMaster";
import { useContext, useEffect, useState, useRef } from 'react';

import { BrowserRouter as Router, Switch, Route, Link, useParams, Prompt, useRouteMatch } from "react-router-dom";

import HomePage from "../web_pages/HomeP/HomePage";

import LogoComp from "./Logo";

import { MasterContext, OrderContext, ScreenSizeContext, ScreenWidthContext, UserContext } from "../ContextApi/context";
import RestaurantPage from "../web_pages/RestaurantPage/RestaurantPage";
import Button from '@mui/material/Button';
import { AddBoxOutlined, HighlightOffOutlined, ArrowDropDownCircle, Logout, Login, ConstructionOutlined } from "@mui/icons-material";
import CateguryPage from "../web_pages/CateguryPage/CateguryPage";
import AllCategurysPage from "../web_pages/CateguryPage/AllCategurysPage";

import MasterBootomBaners from "./MasterBootomBaners";
import Fotter from "./Fotter";
import MoreDetails from "../web_pages/RestaurantPage/MoreDetails";
import OrderTypeSelect from '../OrderSettings/OrderTypeSelect';
import TheOrderList from "../OrderSettings/TheOrderList";
import LoginPage from "../UsersLogs/LoginPage";
import UserDetails from "../UsersLogs/UserDetails";
import ConnUrls from "../Connections/ConnectURL";
import Scroll from 'react-scroll';
import SearchP from "../web_pages/searchP/searchP";
import UserAdresses from "../UsersLogs/UserAdresses";
import MyDetails from "../web_pages/RestaurantPage/MyDetails";
import MyOrders from "../web_pages/RestaurantPage/ForOrderOptions/MyOrders";
import { useHistory } from "react-router";
import SinglePage from "../web_pages/singlePage/SinglePage";

import utilsSinglePage from "../_Utils/utilsSinglePage";
import utilsContactPage from "../_Utils/utilsContactPage";
import ContactPage from "../web_pages/singlePage/ContactPage";
import utilsCategurys from "../_Utils/utilsCategurys";
import Sitemap from "../web_pages/SiteMap/SiteMap";
import ThankPage from "../web_pages/singlePage/ThankPage";
import Page_404 from "../web_pages/Page_404";
import { Carousel } from "react-bootstrap";
import { height } from "@mui/system";
import LolaMobiMnu from "./LolaMobiMnu";
import OrderPlaceOnRest from "../DTechComp/OrderPlaceOnRest";



var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;


const Starter = (props) => {
  const [user, setUser] = useContext(UserContext);
  const [master, setMaster] = useContext(MasterContext);
  const [order, setOrder] = useContext(OrderContext);
  const [screenSize, setScreenSize] = useContext(ScreenSizeContext);
  const [scrolBtnShow, setScrolBtnShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);
  const [showbanners, setShowbanners] = useState(true);
  const [showfotter, setShowfotter] = useState(true);
  const [yesStrip, setYesStrip] = useState(true);



  const [lstSimpePages, setLstSimpePages] = useState([]);
  const [lstContactPages, setLstContactPages] = useState([]);
  const [lstCategurysPages, setLstCategurysPages] = useState([]);
  const history = useHistory();



  let routesLst = [];

  useEffect(() => {
    // setTheScroller();
    loadMaster();
  }, []);

  const setTheScroller = () => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }

  const handleScroll = () => {

    const currentScrollY = window.scrollY;
    if (parseInt(currentScrollY) > 500) {

      if (scrolBtnShow != "scrolBtnShow") { setScrolBtnShow("scrolBtnShow") };
    }
    else {
      setScrolBtnShow("");

    }
  }


  const loadMaster = async () => {

    if (!master) {
      let mast = await masterUtil.getMaster();
      setMaster(mast);
    }

    let pgsNames = await utilsSinglePage.allPgsNames();
    setLstSimpePages(pgsNames)

    setLoading(false);
  }


  const scrolUp = (scInt) => {
    scroll.scrollToTop(scInt);
  }


  const loginUsr = () => {
    if (user?._id) {
      let itm = document.getElementsByClassName("mobiMnu");
      if (itm.length > 0) {
        itm[0].classList.remove("mobMnuSh");
      }
      let itm2 = document.getElementsByClassName("mobiFloatLeft");
      if (itm2.length > 0) {
        itm2[0].classList.add("mobiFloatLShow");
      }
    }
    else {
      history.push("/LoginPage");
    }
  }

  const logOutUsr = () => {
    setUser();
    history.push("/");
  }




  return (

    <div className="Master">

      {loading ? "" :
        <div>
          <Switch>

            {/* <Route exact path="/" component={UserDetails} /> */}
            {/* <Route exact path="/" component={LoginPage} /> */}
            <Route exact path="/" >
              <HomePage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
        
            <Route path="/דף-הבית" >
              <HomePage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>

            {/* <Route path="/mbcats">
              <AllCategurysPage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route> */}
            {/* <Route path="/AboutP">
                <SinglePage pageName={"about"} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>
              <Route path="/TAkanon">
                <SinglePage pageName={"takanon"} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route> */}
            {/* {lstSimpePages?.map((item, index) => {
              if (item.inLinkToPage != "thankU") {

                return (
                  <Route key={`${index}_rout`} path={`/${item.inLinkToPage}`} >
                    <SinglePage key={index} pageName={item.pageName}
                      shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
                  </Route>)
              }
              else {
                return (<Route key={`${index}_rout`} path={`/${item.inLinkToPage}`} >
                  <ThankPage key={index} pageName={item.pageName}
                    shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
                </Route>)
              }
            })} */}

            {/* {lstContactPages?.map((item, index) => {
              return (<Route key={`${index}_rout`} path={`/${item.inLinkToPage}`} >
                <ContactPage key={index} pageName={item.pageName}
                  shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>)
            })}

            {lstCategurysPages?.map((item, index) => {
              let fixdurl = item.pageUrlFix != null && item.pageUrlFix != "" ? item.pageUrlFix : item._id;

              return (<Route key={`${index}_rout`} path={`/${fixdurl}`} >
                <CateguryPage pageId={fixdurl} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
              </Route>)
            })} */}

            {/* <Route path={"/search"} >
              <SearchP shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
            <Route path={"/MyOrder"}>
              <TheOrderList shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
            <Route path={"/TakeOrDel"}>
              <OrderTypeSelect shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                scrollToTop={(stUp) => { scrolUp(stUp) }}
                showfotter={(fot) => setShowfotter(fot)}
                setShowbanners={(bol) => setShowbanners(bol)} />
            </Route>
            <Route path={"/deliAdress"}>
              <UserAdresses shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
            <Route path={"/LoginPage"}>
              <LoginPage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
            <Route path={"/UserDetails"}>
              <UserDetails senderP={"UserDetails"} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
         
            <Route path={"/MyDetails"}>
              <MyDetails shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>


            <Route path={"/MyOrders"}>
              <MyOrders

                shoTopStrp={(tf) => setShowTopMasterStrip(tf)}
                scrollToTop={(stUp) => { scrolUp(stUp) }}
                showfotter={(fot) => setShowfotter(fot)}
                setShowbanners={(bol) => setShowbanners(bol)} />
            </Route> */}

            <Route path="/err404" >
              <Page_404 shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>
            {/* 
           

              <Route component={Page_404} /> */}
            <Route>
              <HomePage shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />
            </Route>

          </Switch>
          {/* <div className={"BottomMast"}>
            {showbanners && yesStrip ? <MasterBootomBaners /> : ""}
            <br />
            {showfotter && yesStrip ? <Fotter /> : ""}
          </div> */}
        </div >

      }

      <Prompt
        // when={shouldBlockNavigation}
        message={(location, action) => {

          //במידה ותפריט הצד פתוח, סוגר אותו לפני מעבר לעמוד אחר
          let itm = window.document.getElementById("mobiMnuID");
          if (itm != null) itm.className = "mobiMnu mobMnuSh";
          let btn = window.document.getElementById("mobiFloatLeftID");
          if (btn != null) btn.className = "mobiFloatLeft";

          // if (order.Orders.length > 0) {
          //   alert("יש פרטים בהזמנה")

          // }
          return true;
        }}
      />
      <ArrowDropDownCircle className={`scrlBtn ${scrolBtnShow}`} onClick={() => scrolUp()} />

    </div >
  );
}

export default Starter;
