import { useContext, useEffect, useRef, useState } from "react";
import { OrderContext } from "../ContextApi/context";

const TheMount = (props) => {

    const [order, setOrder] = useContext(OrderContext);

    const [currentMount, setCurrentMount] = useState(1);

    useEffect(() => {
        let theItm = order.Orders.findIndex(itm => itm.ordProdID == props.itemId);
        if (theItm != -1)
            setCurrentMount(order.Orders[theItm].mount);
    }, [order])


    const addToMount = () => {

        let theItm = order.Orders.findIndex(itm => itm.ordProdID == props.itemId);
        order.Orders[theItm].mount = parseInt(order.Orders[theItm].mount) + 1;
       
        setOrder({ ...order });
    }
    const lessToMount = () => {

        let theItm = order.Orders.findIndex(itm => itm.ordProdID == props.itemId);
        order.Orders[theItm].mount = parseInt(order.Orders[theItm].mount) - 1;
        if (order.Orders[theItm].mount > 0)
            setOrder({ ...order });
        else
        //remover from order
        {
            let filt = order.Orders.filter(itm => itm.ordProdID != props.itemId)
            order.Orders = filt;
            setOrder({ ...order });
            props.remFromOrder();
        }

    }


    return (
        <div className={"prodPlusMinus"}>
            {/* <div className={"mountLn"}>כמות</div> */}
            <div className={"plusBtn"} onClick={() => addToMount()}>+</div>
            <div className={"mountNum"}>
                {currentMount}
            </div>
            <div className={"minusBtn"} onClick={() => lessToMount()}>-</div>
        </div>
    );
}

export default TheMount;
