import { ArrowDropDownCircle } from "@mui/icons-material"
import { RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import ConnUrls from "../../../Connections/ConnectURL";





const SplitForPizza = (props) => {

    let checkerSplit = props.checkerSplit;

    let fullP = "/PermanentImages/fullP.png";
    let halfP = "/PermanentImages/halfP.png";
    let quartP = "/PermanentImages/quartP.png";

    const [fullPCss, setFullPCss] = useState("fullShow");
    const [half1PCss, setHalf1PCss] = useState("");
    const [half2PCss, setHalf2PCss] = useState("");
    const [quartPCss, setQuartPCss] = useState("");
    const [roundPlc, setRoundPlc] = useState("lbQuart");

    const [quarterSelection, setQuarterSelection] = useState(false);

    const allROund = () => { setFullPCss("fullShow"); setHalf1PCss(); setHalf2PCss(); setQuartPCss(); setQuarterSelection(false); }
    const rightHalf = () => { setFullPCss(); setHalf1PCss(); setHalf2PCss("fullShow"); setQuartPCss(); setQuarterSelection(false); }
    const leftHalf = () => { setFullPCss(); setHalf1PCss("fullShow"); setHalf2PCss(); setQuartPCss(); setQuarterSelection(false); }
    const qurt1Select = () => { setFullPCss(); setHalf1PCss(); setHalf2PCss(); setQuartPCss("fullShow"); setRoundPlc((quarterSelection) ? "qurt1Turn" : roundPlc); setQuarterSelection(!quarterSelection); }
    const qurt2Select = () => { setFullPCss(); setHalf1PCss(); setHalf2PCss(); setQuartPCss("fullShow"); setRoundPlc("qurt2Turn"); setQuarterSelection(false); }
    const qurt3Select = () => { setFullPCss(); setHalf1PCss(); setHalf2PCss(); setQuartPCss("fullShow"); setRoundPlc("qurt3Turn"); setQuarterSelection(false); }
    const qurt4Select = () => { setFullPCss(); setHalf1PCss(); setHalf2PCss(); setQuartPCss("fullShow"); setRoundPlc("qurt4Turn"); setQuarterSelection(false); }

    // let showfullPCss = (props.showfullPCss != null) ? props.showfullPCss : false;
    // let showHalfs = (props.showHalfs != null) ? props.showHalfs : true;


    useEffect(() => {

        if (checkerSplit != null) {
            switch (checkerSplit) {
                case "full":
                    {
                        allROund();
                        break;
                    }
                case "lHalf":
                    {
                        leftHalf();
                        break;
                    }
                case "rHalf":
                    {
                        rightHalf();
                        break;
                    }
                case "lbQuart":
                    {
                        qurt1Select();
                        break;
                    }
                case "ltQuart":
                    {
                        qurt2Select();
                        break;
                    }
                case "lbQuart":
                    {
                        qurt3Select();
                        break;
                    }
                case "lbQuart":
                    {
                        qurt4Select();
                        break;
                    }
                default:
                    allROund();
                    break;
            }
        }

    }, [])


    return (
        <div className={`splitSelector`} >
            <img src={fullP} className={fullPCss} onClick={() => { allROund(); props.pizzaSplit("full") }} alt=""/>
            <img src={halfP} className={half1PCss} onClick={() => { leftHalf(); props.pizzaSplit("lHalf") }} alt=""/>
            <img src={halfP} className={`${half2PCss} halfPTurn`} onClick={() => { rightHalf(); props.pizzaSplit("rHalf") }} alt=""/>
            {/* <img src={quartP} className={quartPCss} onClick={() => { qurtSelect(); props.pizzaSplit("rtQuart") }} /> */}
            <img src={quartP} className={`${quartPCss} ${roundPlc}`} onClick={() => { setQuarterSelection(true); qurt1Select(); props.pizzaSplit("lbQuart") }} alt=""/>
            {(quarterSelection) ?
                <div className={"quartSelect"}>
                    <p className={"fullRow noPadding noMargin"}>בחר רבע</p>
                    <img src={quartP} className={quartPCss} onClick={() => { qurt1Select(); props.pizzaSplit("lbQuart") }} alt=""/>
                    <img src={quartP} className={`${quartPCss} qurt2Turn`} onClick={() => { qurt2Select(); props.pizzaSplit("ltQuart") }} alt=""/>
                    <img src={quartP} className={`${quartPCss} qurt3Turn`} onClick={() => { qurt3Select(); props.pizzaSplit("rtQuart") }} alt=""/>
                    <img src={quartP} className={`${quartPCss} qurt4Turn`} onClick={() => { qurt4Select(); props.pizzaSplit("rbQuart") }} alt=""/>
                </div>
                : ""}

        </div>)
}

export default SplitForPizza;