
import { ArrowCircleRightSharp } from '@mui/icons-material';
import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams, useRouteMatch } from "react-router-dom";
import Scroll from 'react-scroll';
import ConnUrls from '../../Connections/ConnectURL';
import { CurrentPContext, MasterContext, OrderContext, ScreenWidthContext } from '../../ContextApi/context';
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import myFunctions from '../../myFunctions';
import utilsSingleP from '../../_Utils/utilsSinglePage';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const SinglePage = (props) => {
  const [order, setOrder] = useContext(OrderContext);
  const [master, setMaster] = useContext(MasterContext);
  const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [allRestsOfCat, setAllRestsOfCat] = useState([]);
  const [cattxtOn, setCattxtOn] = useState([true]);
  const [waitImgItm, setWaitImgItm] = useState({});

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  useEffect(() => {


    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(false)
    getSinglePage();
    setLoading(false);

  }, [])


  const getSinglePage = async () => {
    let pName = props.pageName || window.document.href.substring(window.document.href.lastIndexOf('/') + 1)
    let theSingle = await utilsSingleP.getPageByName(props.pageName);
    setCurrentPage({ ...theSingle, pageTYp: "" });

  }
  const goToBack = () => {
    sessionStorage.setItem("SinglePage", "");
    history.goBack();

  }


  return (
    <div className="SinglePage">
      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(screenWidth > 800) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>
      </div>

      {loading ? "" :
        <>
          {(screenWidth <= 800) ?
            <>
              <div className={"headOnFixC SecondFont bold big"}>
                <div className={`backSd`}>
                  <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>


              </div>
              <div className={`singlePagePad`}>
              </div>
            </>
            :
            ""
          }
          <div className={"spaceRightLong spacePadBig anakMeod bold SecondFontRegular lineHSmall "}>
            {currentPage.mainTitle ? currentPage.mainTitle : ""}</div>
          {/* ימין */}
          <div className={"simplePSides"}>

            {(currentPage.mainImage != null && currentPage.mainImage != "") ?
              <div className={"singlPImg"}>
                <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage}`} alt={currentPage.altTags || currentPage.pageName || ""} />
              </div>
              : ""}

            <div className={"SinglePageContent MyFont big"}>
              {parse(`${currentPage.mainContent}`)}
            </div>
          </div>

          {/* שמאל */}
          <div className={"simplePSides"}>
            {(currentPage.mainImage2 != null && currentPage.mainImage2 != "") ?
              <div className={"singlPImg"}>
                <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage2}`} alt={currentPage.altTags || currentPage.pageName || ""} />
              </div>
              : ""}

            <div className={"SinglePageContent MyFont big"}>
              {parse(`${currentPage.mainContent2}`)}
            </div>
          </div>

          {/* <Prompt
            message={(location, action) => {
              sessionStorage.setItem("scrol", document.scrollY)
              return true;
            }}
          /> */}
          <ScrollingSaver pagNam={"SinglePage"} />


        </>

      }

    </div >
  );
}

export default SinglePage;
