import { CompressOutlined, ConstructionOutlined, ContactsOutlined, ContentCutOutlined } from "@mui/icons-material";
import { Checkbox, FormControl, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useEffect, useState } from "react";
import { Prompt, useHistory } from "react-router";
import myFunctions from "../../../myFunctions";
import TheMoreThenOneMount from "../../../OrderSettings/TheMoreThenOneMount";
import SplitForPizza from "./SplitForPizza";

const OrderChkSel = (props) => {

    const [theItem] = useState(props.itm);
    const [theLstDisp, setTheLstDisp] = useState(props.indx);

    // const [cheksBack, setCheksBack] = useState(theItem.selected != "" ? theItem.selected : []);

    let tempChks = (theItem.selected != "") ? theItem.selected : [];

    let selectedCounting = 0;


    useEffect(() => {
        getOpts(theItem.selected)
        selectedCounting = myFunctions.getTotalSelected(theItem.selected);
    }, [])


    let totalSplits = 0;

    const SplitForPizzaFunc = (item, splt, fixedPrice) => {


        // //מחפש את מיקום הצ'ק בוקס ברשימת הצ'ק בוקסים
        let curChk = tempChks.findIndex(it => it.servType == item.servType);

        // if (tempChks[curChk].splitSelected != undefined) {
        //     tempChks[curChk].splitSelected = splt;
        //     tempChks[curChk] = { ...tempChks[curChk] }

        // }
        // else {
        tempChks[curChk] = { ...tempChks[curChk], splitSelected: splt }

        // }
    

        getOpts(tempChks);
        // setCheksBack(tempChks);
        props.ContCallBack(tempChks)

    }

    //מסמן צ'ק בוקס חובה כי אין משהו שנבחר
    const updateChkByDefault = (item, fixedPrice) => {


        let itmToPush = { ...item }
        if (itmToPush.splitSelected == null) {
            itmToPush.splitSelected = "full";
        }

        itmToPush.addToPrice = fixedPrice;

        tempChks.push(itmToPush);


        getOpts(tempChks);

        // setCheksBack(tempChks);

        props.ContCallBack(tempChks)
    };

    // let splits = 0;



    const updateChkSelection = (item, fixedPrice, chkVal, setByMustSelect) => {

        if (setByMustSelect) {
            let itmToPush = { ...item }
            if (itmToPush.splitSelected == null) {
                itmToPush.splitSelected = "full";
            }

            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }
        else {
            let curChk = tempChks.findIndex(it => it.servType == item.servType);
            if (curChk != -1) {
                if (chkVal == false) {
                    tempChks.splice(curChk, 1);

                    for (let i = 0; i < tempChks.length; i++) {
                        // tempChks[i].addToPrice = (i < theItem.freeAdds) ? 0 : tempChks[i].addToPrice
                    }
                }
            }
            else {
                let itmToPush = { ...item }
                if (itmToPush.splitSelected == null) {
                    itmToPush.splitSelected = "full";
                }

                itmToPush.addToPrice = fixedPrice;
                itmToPush.itmsMount = 1;

                tempChks.push(itmToPush);
            }
        }

        //מעדכן כמה פריטים נבחרו עד כה ברשימה המתקבלת
        selectedCounting = myFunctions.getTotalSelected(tempChks);

        getOpts(tempChks);

        props.ContCallBack(tempChks)
    };

    const updateRdbSelection = (item, fixedPrice, chkVal, setByMustSelect) => {


        if (setByMustSelect) {
            let itmToPush = { ...item }
            itmToPush.splitSelected = "full";

            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }
        else {

            tempChks = [];

            let itmToPush = { ...item }
            if (itmToPush.splitSelected == null) {
                itmToPush.splitSelected = "full";
            }

            itmToPush.addToPrice = fixedPrice;
            itmToPush.itmsMount = 1;

            tempChks.push(itmToPush);
        }


        getOpts(tempChks);
        props.ContCallBack(tempChks)
    };


    const updatMount = (item, newVal) => {
        let itmToPush = { ...item }

        let foundIt = tempChks.findIndex(itm => itm._id == itmToPush._id);
        if (foundIt != -1) {
            tempChks[foundIt].itmsMount = newVal
        }

        selectedCounting = myFunctions.getTotalSelected(tempChks);

        getOpts(tempChks);
        props.ContCallBack(tempChks)
    }

    const clearchkBx = (item, priceAfterFix) => {
        updateChkSelection(item, priceAfterFix)
    }

    // ***************   MAIN     *****************  
    const getOpts = (theLst) => {

        let theSplits = theItem.splitSelection ? true : false;

        let Allselected = 0;

        let setByMustSelect = false;

        let inTheloop = false;

        if (theItem.mustSelect && theLst.length == null) {
            let chkd = true;
            let disabledOr = false;
            let checkerSplit = theItem.options[0].splitSelected;
            let priceAfterFix = theItem.options[0].addToPrice;

            if (checkerSplit != "full" && checkerSplit != "") {
                Allselected = parseFloat(Allselected) + 0.5;
                priceAfterFix = theItem.options[0].addToPrice / 2;
            }
            else {
                Allselected = parseFloat(Allselected) + 1;
                priceAfterFix = theItem.options[0].addToPrice;
            }

            if (Allselected <= theItem.freeAdds) {
                priceAfterFix = 0;
            }



            updateRdbSelection(theItem.options[0], theItem.options[0].addToPrice, true, true)
            inTheloop = true;

        }

        if (!inTheloop) {
            let opts = theItem.options.map((item, index) => {


                setByMustSelect = (index == 0) ? setByMustSelect : false;
                let chkd = false;

                let checkerSplit = null;

                let showfullPCss = true;
                let showHalfs = true;


                let itemsMount = 1;

                //מוגבל למספר מסויים של בחירות
                // let disabledOr = !theItem.mustSelect ? (theItem.maxSelection == 0) ? false : (theItem.maxSelection <= theLst.length) : false;


                let disabledOr = false;
                if (theItem.maxSelection == 0) {
                    disabledOr = false
                }
                else {
                    if (theItem.maxSelection <= myFunctions.getTotalSelected(theLst) || setByMustSelect) {
                        disabledOr = true;
                    }

                }

                // !theItem.mustSelect ? (theItem.maxSelection == 0) ? false : (theItem.maxSelection <= theLst.length) : false;

                let priceAfterFix = item.addToPrice;
            
                //תוספות נלווים בחינם

                if (theItem.freeAdds != 0 && theItem.freeAdds != null) {
                    priceAfterFix = (theLst.length == null || theLst.length < theItem.freeAdds) ? 0 : priceAfterFix;
                }

                for (let i = 0; i < theLst.length; i++) {

                    if (theLst[i].servType == item.servType) {

                        chkd = true;
                        disabledOr = false;
                        checkerSplit = theLst[i].splitSelected;

                        if (checkerSplit != "full" && checkerSplit != null) {
                            Allselected = parseFloat(Allselected) + 0.5;
                            priceAfterFix = theLst[i].addToPrice / 2;
                        }
                        else {
                            Allselected = parseFloat(Allselected) + 1;
                            priceAfterFix = theLst[i].addToPrice;
                        }

                        if (Allselected <= theItem.freeAdds) {
                            priceAfterFix = 0;
                        }

                        itemsMount = theLst[i].itmsMount;
                    }
                }


                let thisRoundChkBox = "";

                ///במידה ומוגדר בחירה חובה
                if (theItem.mustSelect) {

                    if (theLst.length == 0 && index == 0) {
                        updateRdbSelection(item, priceAfterFix, !chkd, setByMustSelect)
                        chkd = true;
                    }

                    ///במידה ומוגדר בחירה חובה ומרובה
                    if (theItem.chkMoreThenOne || theItem.maxSelection > 1) {
                        thisRoundChkBox = <Checkbox checked={chkd}
                            // sx={{ color: "var(--turqiText)" }}
                            onChange={() => updateChkSelection(item, priceAfterFix, !chkd)}
                        />
                    }
                    else {
                        thisRoundChkBox = <Radio checked={chkd}
                            // sx={{ color: "var(--turqiText)" }}

                            onChange={() => updateRdbSelection(item, priceAfterFix, !chkd)}
                        />
                    }
                }
                else {
                    thisRoundChkBox = <Checkbox checked={chkd}
                        // sx={{ color: "var(--turqiText)" }}
                        onChange={() => updateChkSelection(item, priceAfterFix, !chkd)}
                    />
                }


                return (
                    <div key={index} className={`selectionChk ${theItem.chkSideBySide ? "sideBySide" : ""}`}>

                        <FormControlLabel control={
                            thisRoundChkBox
                        }
                            sx={{ fontFamily: "var(--SecondFont)" }}
                            className={"FirstFont"}
                            label={item.servType}
                            disabled={disabledOr}
                        />

                        <div className={"changePrice"}>
                            {(priceAfterFix != 0) ? `+ ${priceAfterFix} ₪` : ``}
                        </div>
                        {(theSplits && chkd) ?
                            <div className={`pizzaSplt ${(priceAfterFix != null && priceAfterFix > 0) ? "" : " splitSelectorSpacNoPrice"} onLeft`} >
                                <SplitForPizza checkerSplit={(checkerSplit != null) ? checkerSplit : "full"}
                                    showHalfs={showHalfs} showfullPCss={showfullPCss}
                                    pizzaSplit={(splt) => SplitForPizzaFunc(item, splt, priceAfterFix)} />
                            </div>
                            : ""}
                        {theItem.chkMoreThenOne && chkd ?
                            <div id={`M_` + index} className={`ordItmLine TheMoreThenOneMount`}>
                                <span className={"bold itemMountLf "}>
                                    <TheMoreThenOneMount key={`MTO_${index}`} css={"prodPlusMinus"}
                                        Mount={itemsMount}
                                        minCount={1}
                                        maxSelection={theItem.maxSelection}
                                        selectedCounting={selectedCounting}
                                        itemId={item.id}
                                        clearchkBx={() => clearchkBx(item, priceAfterFix)}
                                        remFromOrder={(bak) => { item.mount = bak }}
                                        changeVal={(newVal) => { updatMount(item, newVal) }}
                                    />
                                </span>
                            </div> : ""}


                    </div>
                )
            })

            setTheLstDisp(opts)

        }




    }


    const getSplit = (spltSelect) => {
        let steper = 0;
        switch (spltSelect) {
            case "full":
                {
                    steper = 1;
                    break;
                }
            case "lHalf":
                {
                    steper = 0.5;
                    break;
                }
            case "rHalf":
                {
                    steper = 0.5;
                    break;
                }
            case "rtQuart" ||
                "rbQuart" ||
                "ltQuart" ||
                "lbQuart":
                {
                    steper = 0.5;
                    break;
                }

            default:
                {
                    steper = 0;
                    break;
                }
        }

        return steper
    }

    return (
        <div className="prodSelect">
            <div className="optSelHeads">{`${theItem.optionName} ${theItem.mustSelect ? "" : ""}`}</div>
            <div>
                {theLstDisp}
            </div>

        </div>
    )
}

export default OrderChkSel;
