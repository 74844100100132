
import React, { useEffect, useState,useContext } from 'react';
import ConnUrls from '../../Connections/ConnectURL';
import BigPopUpMsg from '../BigPopUpMsg';
import PopUpMsg from '../PopUpMsg';
import { MasterContext } from '../../ContextApi/context';



const TableOrder_Confirmd = (props) => {
    const [master] = useContext(MasterContext);

    return (
        <PopUpMsg Title={["ההזמנה התקבלה וממתינה לאישור"]}
            ContentText={[`מספר הזמנה: ${props.TblorderNumber}`
                , "לאחר אישור ההזמנה על ידי המסעדה"
                , "ישלח SMS"]}
            inLabel={""}
            okClick={() => { props.okClick() }}
            errorMsg={""}
            BottomTitle={["בתאבון!"]}
            cssStyl={"approveMsgPopup"}
            topImg={`${ConnUrls.servMediasUrl}/${master.orderApprovImg}`} />
    )
}


export default TableOrder_Confirmd
