import { useContext, useState } from 'react';
import parse from 'html-react-parser';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import SwiperCore, { Pagination } from 'swiper';
import { CurrentPContext, MasterContext, ScreenWidthContext } from '../ContextApi/context';
import { Link } from '@mui/material';
import ConnUrls from '../Connections/ConnectURL';

SwiperCore.use([Pagination]);


const MasterBootomBaners = (props) => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);

  const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);
  const [theList] = useState(screenWidth > 800 ? master.bootomDeskTopBaners : master.bootomBaners);
  return (
    <>
      <div className={"spaceRightLong MbigFont bold SecondFont"}>
        עקבו אחרינו
      </div>
      <br />
      <div className={"BottomBannerImages"}>
        <Swiper
          modules={[Pagination]}
          className={"botBannerSwiper"}
          spaceBetween={10}
          slidesPerView={screenWidth <= 800 ? 1 : 3}
          loop={false}
          height={screenWidth <= 800 ? 500 : 500}
          // onSlideChange={() => console.log('slide change')}
          freeMode={false}
        >

          {
            theList.map((item, index) => {
              return (
                <SwiperSlide key={index} className={"WebPage HPTopImagesLst BottomMastBtn"}>

                  <a href={`${item.txt}`} target={'_blank'}>
                    <div className={"HPTImgWrap"}>
                      <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""}/>
                    </div>
                  </a>
                </SwiperSlide>
              )

            })
          }

        </Swiper>

      </div>
    </>
  )

}

export default MasterBootomBaners;



