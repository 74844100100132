// let isDev = false;
// let isDev = true;
const isDev = process.env.NODE_ENV === 'development';


class ConnUrls {
    static ApiUrl = isDev ? 'http://localhost:8000/api/' : 'https://lola-ashdod.herokuapp.com/api/';
    static mediasUrl = isDev ? 'http://localhost:3000' : 'https://lola-ashdod.herokuapp.com';
    static mediasLocalUrl = isDev ? 'http://localhost:4001' : 'https://lola-ashdod.netlify.app/';
    // static servMediasUrl = isDev ? 'http://localhost:8000/images/' : 'https://lolaimgs.s3.eu-central-1.amazonaws.com/images/';
    static servMediasUrl = 'https://lolaimgs.s3.eu-central-1.amazonaws.com/images';
    static socketClient = isDev ? 'http://localhost:9000/' : 'https://socket.lola-ashdod.herokuapp.com/';
}
export default ConnUrls;