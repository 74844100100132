import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getHomePage = async () => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "homeP");        
    return (dFromF);
}



export default {
    getHomePage
};