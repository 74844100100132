import apiTablesOrders from "../_ApiConn/apiTablesOrders";


const createTableOrder = async (theParms) => {
    let dFromF = await apiTablesOrders.createTableOrder(theParms);
    return (dFromF.data);
}

export default {
  
    createTableOrder
};