import { useEffect } from "react";
import Scroll from 'react-scroll';



var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const ScrollingSaver = (props) => {

    useEffect(() => {
        scrolToMe(sessionStorage.getItem(props.pagNam))
        window.addEventListener("scroll", getTheScroll);
        return () => window.removeEventListener("scroll", getTheScroll)

    }, []);


    const getTheScroll = () => {
        const currentScrollY = window.scrollY;
        sessionStorage.setItem(props.pagNam, currentScrollY)
    }

    const scrolToMe = (scInt) => {
        scroll.scrollTo(scInt);
    }

    return (<></>)

}

export default ScrollingSaver;