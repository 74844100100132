import restMenus from "../_ApiConn/apiRestMenus";


const getRestMenu = async () => {
    const token = 'innerToken';
    let dFromF = await restMenus.getRestMenu({token});
    return (dFromF);
}


// const getMnuByRestId = async (restId) => {
//     const token = localStorage.getItem('token');
//     let dFromF = await restMenus.getMnuByRestId({token,restId});
//     return (dFromF);
// }

// const createRestMnu = async (obj) => {
//     let dFromF = await restMenus.createRestMnu(obj);
//     return (dFromF);
// }


// const removeFromMenus = async (id) => {
//     let dFromF = await restMenus.removeFromMenus(id);
//     return (dFromF);
// }

export default {
    getRestMenu

    // getMnuByRestId,
    // createRestMnu,
    // removeFromMenus
};