import React, { useState, createContext, useEffect } from 'react';
import utilsPageSections from '../_Utils/utilsPageSections';


export const SectionsContext = createContext();

export const SectionsContextProvider = props => {
    const [Sections, setSections] = useState([]);

    useEffect(() => {
        setIt()
    }, [])

    const setIt = async() => {
        let tempo = await utilsPageSections.allPgsSectionsFull();
        setSections(tempo)
    }


    useEffect(() => {
    }, [Sections])


    return (
        <SectionsContext.Provider value={[Sections, setSections]}>
            {props.children}
        </SectionsContext.Provider>
    )
}

