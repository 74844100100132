
// import React from "react";
// import DynamicSitemap from "react-dynamic-sitemap";
// import parse from 'html-react-parser';

import Routes from './SiteMap/TheRouts';
import { useEffect } from "react";
import { useState } from "react";
import { CompressOutlined } from "@mui/icons-material";
import parse from 'html-react-parser';
import ConnUrls from '../Connections/ConnectURL';

var fs = require('fs');
var builder = require("xmlbuilder");

// const router = require('../web_pages/Routes');



const Page_404 = (props) => {

    const [stMapBack, setStMapBack] = useState();

    useEffect(() => {
        // props.shoTopStrp(false)
        // props.showfotter(false)
        // props.setYesStrip(false)

    }, [])

    useEffect(async () => {

        // console.log(stmap)


    }, []);




    return (
        <>
            <div className={"page_404"}>
                <p class={"big"}>אופססס!</p>
                <p class={"small"}>דף זה לא נמצא</p>
                <br />
                <a href='/'>המשך</a>
                <div className={"imgOn404"} style={{ backgroundImage: `url(${ConnUrls.servMediasUrl}/Uploads/MASTER/ops.jpg)` }}></div>
            </div>
        </>
    )


}

export default Page_404;