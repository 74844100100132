import { useState } from "react";
import { useContext, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Route, Switch } from "react-router";
import { CurrentPContext, MasterContext } from "../ContextApi/context";
import masterUtil from "../_Utils/utilsMaster";
import Sitemap from "../web_pages/SiteMap/SiteMap";
import '../Styles/Style.css'
import '../Styles/LolaStyle.css'
import '../Styles/MySetStyle.css'
import '../Styles/DesktopStyle.css'
import '../Styles/MobiStyle.css'
import '../Styles/animate.css'
import '../Styles/bootstrap.min.css'

import parse from 'html-react-parser';



const MasterHeadComp = () => {

  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (master == null) {
      setThemast();
    }

    setLoading(false)
  }, [])

  let canonicalURl = "";

  const setThemast = async () => {
    let mast = await masterUtil.getMaster();

    setMaster(mast);

  }
  return (
    <>
      {loading ? "" :
        <Helmet

          title={currentPage.pageName || master?.mainTitle || "לולה אשדוד"}
          meta={[
            {
              name: "description",
              property: "og:description",
              content: master?.sEOdescription || master?.mainTitle || ""
            },
            {
              name: "keywords",
              property: "og:keywords",
              content: master?.sEOkeywords || master?.mainTitle || ""
            },
            { property: "og:title", content: currentPage.pageName || master?.mainTitle || "לולה אשדוד" },
            { property: "og:url", content: decodeURI(window.location.href) }
            // { property: "og:image", content: seo.image },
            // { property: "og:image:type", content: "image/png" },

          ]}

        >
          <link rel="canonical" href={decodeURI(window.location.href)} />
          <link rel="alternate" href={decodeURI(window.location.href)} hreflang="he" />
        </Helmet>




      }
    </>
  );
}

export default MasterHeadComp;
