import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { CancelRounded } from '@mui/icons-material';

const PopUpTheComp = (props) => {


    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    useEffect(() => {

    }, [])

    const saveMe = () => {
        props.okClick();
        handleClose();

    };


    const handleClose = () => {
        props.closePop();
    };

    return (
        <Dialog open={open} onClose={handleClose} className={`popDiagBlock ${props.cssStyl ? props.cssStyl : ""}`}>
            <CancelRounded className={"popUpCloseBtn pointing"} onClick={() => handleClose()} />
            {props.Title != "" ?
                <DialogTitle className={`theTitlePop ${props.theTitlePopCss}`}>
                    {props.Title.map((txtItm, index) => (
                        <span key={index}>{txtItm}<br /></span>
                    ))}
                </DialogTitle>
                : ""}
            <DialogContent>
                {props.err ? <div style={{ color: 'red', fontWeight: 'bold' }}>{props.errorMsg}</div> : ""}
                {/* Replace DialogContentText with div */}
                {props.contentComponent ? React.createElement(props.contentComponent, null, props.theInsideComp) : (
                    <div>
                        {props.theInsideComp}
                    </div>
                )}
            </DialogContent>
            <DialogActions >
                {/* {
                    <button type="button" className="btnPopRegular pointing onRight" onClick={() => { props.okClick(); handleClose(); }}>
                        {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}</button>} */}

                {props.btnClick2 != null ?
                    <>
                        <span>&nbsp;&nbsp;</span>
                        <button type="button" className="btnPopRegular pointing onLeft"
                            onClick={() => { props.btnClick2(); handleClose(); }}>
                            {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</button>
                    </>
                    : ""}
            </DialogActions>
         <div className="BottomMastScroll"></div>

        </Dialog >

    );
}

export default PopUpTheComp;