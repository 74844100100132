import pageSections from "../_ApiConn/apiPageSections";


const getPageSectionById = async (idToGet) => {
    let dFromF = await pageSections.getPageSectionById(idToGet);
    return (dFromF.data);
}

const allPgsSects = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await pageSections.allPgsSects(token);
    return (dFromF.data);
}

const allPgsSectionsFull = async () => {
    const token = localStorage.getItem('token');
    let dFromF = await pageSections.allPgsSectionsFull(token);
    return (dFromF.data);
}

const deletePageSection = async (id, obj) => {
    let dFromF = await pageSections.deletePageSection(id, obj);
    return (dFromF.data);
}
const updateSingle = async (currSingle) => {

    if (currSingle._id) {
        let dFromF = await pageSections.updateSingle(currSingle._id, currSingle);
        return (dFromF.data);
    }

}

const createPageSection = async () => {
    let dFromF = await pageSections.createPageSection();
    return (dFromF.data);
}

const getSectionByName = async (name) => {
    let dFromF = await pageSections.getSectionByName(name, { obj: "" });
    return (dFromF.data[0]);
}

export default {
    getPageSectionById,
    allPgsSectionsFull,
    updateSingle,
    getSectionByName,
    createPageSection,
    allPgsSects,
    deletePageSection
    
};