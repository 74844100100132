import React, { useEffect, useState } from "react";
import parse from 'html-react-parser';
import { Helmet } from "react-helmet";

// The import below should be updated to match your Router component
// import Routes from "../createRoutes";
import DynamicSitemap from "react-dynamic-sitemap";
import TheRouts from "./TheRouts";

const Sitemap = (props) => {

    const [rots, setRots] = useState();
    const [bk, setBk] = useState();
    const [stl, setStl] = useState();




    useEffect(() => {
        setIt();

    }, [])

    const setTheXml = () => {

    }


    const setIt = async () => {
        let theRots = await TheRouts()
        setRots(theRots)


        let routs = DynamicSitemap({ ...props, routes: theRots, prettify: true })

        let fixdshort = routs.props.children.props.children;
        console.log(routs.props.children.props)

        setRots((fixdshort))

        setStl(parse(theStyle()));
    }

    return (
        <>
            {rots ?
                <div style={{
                    whiteSpace: "pre-wrap",
                    direction: "ltr",
                    textAlign: "left"
                }}>
                    {rots}
                </div>
                : []}
        </>
    )

}

export default Sitemap





const theStyle = () => {
    return (`<style xmlns="http://www.w3.org/1999/xhtml" id="xml-viewer-style">/* Copyright 2014 The Chromium Authors. All rights reserved.
 * Use of this source code is governed by a BSD-style license that can be
 * found in the LICENSE file.
 */

:root {
  color-scheme: light dark;
}

div.header {
    border-bottom: 2px solid black;
    padding-bottom: 5px;
    margin: 10px;
}

@media (prefers-color-scheme: dark) {
  div.header {
    border-bottom: 2px solid white;
  }
}

div.folder &gt; div.hidden {
    display:none;
}

div.folder &gt; span.hidden {
    display:none;
}

.pretty-print {
    margin-top: 1em;
    margin-left: 20px;
    font-family: monospace;
    font-size: 13px;
}

#webkit-xml-viewer-source-xml {
    display: none;
}

.opened {
    margin-left: 1em;
}

.comment {
    white-space: pre;
}

.folder-button {
    user-select: none;
    cursor: pointer;
    display: inline-block;
    margin-left: -10px;
    width: 10px;
    background-repeat: no-repeat;
    background-position: left top;
    vertical-align: bottom;
}

.fold {
    background: url("data:image/svg+xml,&lt;svg xmlns='http://www.w3.org/2000/svg' fill='%23909090' width='10' height='10'&gt;&lt;path d='M0 0 L8 0 L4 7 Z'/&gt;&lt;/svg&gt;");
    height: 10px;
}

.open {
    background: url("data:image/svg+xml,&lt;svg xmlns='http://www.w3.org/2000/svg' fill='%23909090' width='10' height='10'&gt;&lt;path d='M0 0 L0 8 L7 4 Z'/&gt;&lt;/svg&gt;");
    height: 10px;
}
</style>`)
}