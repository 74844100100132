import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

import SwiperCore, {
  A11y, Autoplay, EffectCoverflow, Keyboard, Mousewheel, Navigation, Pagination, Scrollbar
} from 'swiper';

import { useContext, useEffect, useState } from 'react';
import { MasterContext, MobiOrNotContext, ScreenSizeContext } from "../../ContextApi/context";
import { SectionsContext } from "../../ContextApi/Sections_Context";

import utilsHomePage from "../../_Utils/utilsHomePage";

import { useHistory } from "react-router";

import ConnUrls from "../../Connections/ConnectURL";
import SectionItem from "./Sections/SectionItem";
import DeskMenu from '../../Master/DeskMenu';
import LolaMobiMnu from '../../Master/LolaMobiMnu';


SwiperCore.use([
  Autoplay, Keyboard, Mousewheel, EffectCoverflow,
  A11y, Pagination, Scrollbar, Navigation
]);



const HomePage = (props) => {

  const [screenSize, setScreenSize] = useContext(ScreenSizeContext);
  const [master, setMaster] = useContext(MasterContext);
  const [mobiOrNot, setMobiOrNot] = useContext(MobiOrNotContext);
  const [activeIndx, setActiveIndx] = useState(0);
  const [firstRound, setFirstRound] = useState(true);

  const [Sections, setSections] = useContext(SectionsContext);

  const history = useHistory();
  const [waitImgItm, setWaitImgItm] = useState({});

  const [socialMds, setSocialMds] = useState([]);

  const [swiper, setSwiper] = useState(null);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    props.scrollToTop();
    getHome()

  }, [])

  const getHome = async () => {
    let hPage = await utilsHomePage.getHomePage();
    // let sects = await utilsPageSections.allPgsSects();

    // setLogurl(master.webSiteLogo)

    setLoading(false)

  }


  const sliderGoTo = (ind) => {
    swiper.slideTo(ind + 1)
  }

  const serachBuis = (txtSearch) => {
    history.push("/search?txSrch=" + txtSearch);
  }

  return (
    <>
      {
        loading ? "Loading" :
          <>

            {(mobiOrNot) ?
              <LolaMobiMnu activeIndx={activeIndx}
                goToSld={(ind) => sliderGoTo(ind)} /> :
              <DeskMenu activeIndx={activeIndx}
                goToSld={(ind) => sliderGoTo(ind)}

              />}

            <div className={"fullBk"} style={{
              width: screenSize?.width || window.innerWidth,
              height: screenSize?.height || window.innerHeight
            }} >

              {/* {<div className={"fullBkImgWrap"} style={{ width: screenSize.width, height: screenSize.height }}>
              <img className={"fullBkImg"}
                src={`${ConnUrls.servMediasUrl}/Uploads/MASTER/onlne.png`} alt={""} />
              <div className={" bold big pointing"} >
                {"hello"}
              </div>
            </div>} */}
              <Swiper
                effect={"coverflow"}
                coverflowEffect={{
                  rotate: 20,
                  stretch: 25,
                  depth: 250,
                  modifier: 1,
                  slideShadows: false,
                }}
                modules={[Navigation, Pagination, Scrollbar, A11y]}
                className={""}
                initialSlide={activeIndx}
                activeindex={activeIndx}
                spaceBetween={0}
                slidesPerView={1}
                pagination={{ clickable: true }}
                direction={'vertical'}
                mousewheel={true}
                loop={true}
                speed={700}
                // autoplay={{ delay: 3000 }}
                autoplay={false}
                onSwiper={setSwiper}
                onSlideChange={(swiper) => { setActiveIndx(swiper.activeIndex) }}
                freeMode={false}
              // onSwiper={(swiper) => console.log(swiper)}
              >
                {
                  Sections.map((item, index) => {
                    return (
                      <SwiperSlide key={index} className={"animate__animated"}>
                        <div className={"fullBkImgWrap"}
                          style={{
                            width: screenSize?.width || window.innerWidth,
                            height: screenSize?.height || window.innerHeight
                          }}>

                          {(mobiOrNot && item.mainImageMobile!="" && (item.mainImageMobile!=null)) ?
                            <img className={"fullBkImg"}
                              src={`${ConnUrls.servMediasUrl}/${item.mainImageMobile}`} alt={""} />:
                              <img className={"fullBkImg"}
                              src={`${ConnUrls.servMediasUrl}/${item.mainImage}`} alt={""} />
                          }

                          <SectionItem item={item} activeIndx={activeIndx} itemIndx={index} />
                        </div>
                      </SwiperSlide>

                    )

                  })
                }

              </Swiper>
            </div>
          </>

      }
    </>

  )
}

export default HomePage;
