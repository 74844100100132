
import { useContext, useEffect, useState } from 'react';
import { CurrentPContext, MasterContext, OrderContext, ScreenWidthContext } from '../../ContextApi/context';
import utilsSingleP from '../../_Utils/utilsSinglePage';
import { useHistory } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { ArrowCircleDownSharp, ArrowBackIosNew, ArrowCircleUp, ArrowCircleRightSharp } from '@mui/icons-material';
import OrderFunctions from '../../OrderSettings/OrderFunctions';
import Scroll from 'react-scroll';
import { Prompt } from "react-router";
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import parse from 'html-react-parser';
import myFunctions from '../../myFunctions';
import ConnUrls from '../../Connections/ConnectURL';
import utilsOrders from '../../_Utils/utilsOrders';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const ThankPage = (props) => {
  const [order, setOrder] = useContext(OrderContext);
  const [master, setMaster] = useContext(MasterContext);
  const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [loading, setLoading] = useState(true);
  const [waitImgItm, setWaitImgItm] = useState({});
  const [bkparams, setBkparams] = useState();

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  }

  useEffect(() => {


    setWaitImgItm(myFunctions.setTheImgWait(currentPage, master));

    props.shoTopStrp(false)
    getSinglePage();
    getParmeters();
    setLoading(false);

  }, [])

  const getParmeters = async () => {
    let decode = window.document.URL.split("confirm=")[1] || null;
    if (decode) {
      let ans = parseJwt(decode);
      let prms = {
        decode: ans,
        orderId: ans.orderId,
        transactionId: ans.transactionId,
        jtoken: ans.jtoken
      }

      let upPrms = await utilsOrders.updateOrderPayParms(prms);


      setBkparams(prms);

    }
    else {
      history.push("/404")
    }
  }


  const parseJwt = (token) => {

    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  const getSinglePage = async () => {
    let pName = props.pageName || window.document.href.substring(window.document.href.lastIndexOf('/') + 1)
    let theSingle = await utilsSingleP.getPageByName(props.pageName);
    setCurrentPage({ ...theSingle, pageTYp: "" });

  }
  const goToBack = () => {
    sessionStorage.setItem("SinglePage", "");
    history.goBack();

  }


  return (
    <div className="SinglePage">
      <div className={loading ? "imgWaiting" :
        `imgWaiting animate__animated animate__fast animate__zoomOut ${waitImgItm.imgDelay}`}
        style={{
          backgroundImage: `url(${ConnUrls.servMediasUrl}${waitImgItm.waitImg})`,
          backgroundPosition: `${waitImgItm.imgBackPos}`,
          backgroundSize: `${(screenWidth > 800) ? "25%" : waitImgItm.backgroundSize}`,
          backgroundRepeat: `${waitImgItm.backgroundRepeat}`
        }}>
      </div>

      {loading ? "" :
        <>
          {(screenWidth <= 800) ?
            <>
              <div className={"headOnFixC SecondFont bold big"}>
                <div className={`backSd`}>
                  <ArrowCircleRightSharp onClick={() => goToBack()} />
                </div>


              </div>
              <div className={`singlePagePad`}>
              </div>
            </>
            :
            ""

          }

          <div className={"chargedMsg"}>


            {
              (bkparams != null) ?
                <div className={"innerChargMsg"}>
                  <div className={"spaceRightLong spacePadBig anakMeod bold SecondFontRegular lineHSmall "}>
                    {currentPage.mainTitle ? currentPage.mainTitle : "חוייבת בהצלחה."}</div>
                  {/* ימין */}

                  <div className={"prmsDiv"}>
                    <div className={"big"}></div>
                    <div className={"big"} value="">מספר הזמנה: {bkparams.orderId}</div>
                    <div className={"big"}>מזהה עסקה: {bkparams.transactionId}</div>
                    <div className={"big"}>טוקן: {bkparams.jtoken}</div>

                  </div>
                  <div type="button" className="centerMe btn btn-success  bottomBtn pointing" onClick={() => history.push("/")}>המשך</div>
                </div>

                : ""
            }
          </div>

          <div className={"simplePSides"}>

            {(currentPage.mainImage != null && currentPage.mainImage != "") ?
              <div className={"singlPImg"}>
                <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage}`} alt={currentPage.altTags || currentPage.pageName || ""} />
              </div>
              : ""}

            <div className={"SinglePageContent MyFont big"}>
              {parse(`${currentPage.mainContent}`)}
            </div>
          </div>

          {/* שמאל */}
          <div className={"simplePSides"}>
            {(currentPage.mainImage2 != null && currentPage.mainImage2 != "") ?
              <div className={"singlPImg"}>
                <img src={`${ConnUrls.servMediasUrl}${currentPage.mainImage2}`} alt={currentPage.altTags || currentPage.pageName || ""} />
              </div>
              : ""}

            <div className={"SinglePageContent MyFont big"}>
              {parse(`${currentPage.mainContent2}`)}
            </div>
          </div>

          {/* <Prompt
            message={(location, action) => {
              sessionStorage.setItem("scrol", document.scrollY)
              return true;
            }}
          /> */}
          <ScrollingSaver pagNam={"SinglePage"} />



        </>

      }

    </div >
  );
}

export default ThankPage;
