import { ShoppingBag, SportsMotorsports } from "@mui/icons-material";

import AddressLine from "../web_pages/RestaurantPage/RestDetails/addressLine";
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';




const getOrderTotalPrice = (theOrder) => {

    let tot = 0;
    if (theOrder.OrderType == "Deliver") {
        tot = parseInt(theOrder.OrderDelPrice);
    }
    theOrder.Orders.map((item, index) => {

        if (item.specials.length > 0) {
            item.specials.map((special, specialIndex) => {
                special.spec.map((spec, specIndex) => {
                    if (parseFloat(spec.addToPrice) > -1) {
                        tot += (parseFloat(spec.addToPrice) * parseFloat(spec.itmsMount)) * parseInt(item.mount);

                    }
                })
            })
        }
        tot += parseFloat(item.prodPrice) * parseInt(item.mount);

    })
    tot += parseFloat(getStickedSauces(theOrder));

    return tot;
}

const getOrderPrice = (theOrder) => {
    let tot = 0;

    theOrder.Orders.map((item, index) => {

        if (item.specials.length > 0) {
            item.specials.map((special, specialIndex) => {
                special.spec.map((spec, specIndex) => {
                    if (parseFloat(spec.addToPrice) > -1) {
                        tot += (parseFloat(spec.addToPrice) * parseFloat(spec.itmsMount)) * parseInt(item.mount);

                        // console.log(parseFloat(spec.addToPrice))
                        // console.log(parseFloat(spec.itmsMount))
                        // console.log(parseFloat(item.mount))


                    }
                })
            })
        }
        tot += parseFloat(item.prodPrice) * parseInt(item.mount);
    })
    tot += parseFloat(getStickedSauces(theOrder));

    return tot;
}

const takeAwayBlock = (theTime, Address) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <ShoppingBag />
                </div>
                {/* <div className={"theImg"} style={{ backgroundImage: `url("/PermanentImages/take.png")` }}></div> */}
                <div className={"txtOrdType"}>

                    <div className={"bold pickOrDel"} >איסוף עצמי</div>
                    <div className={"regular"} >{theTime}</div>
                    {/* <div className={"regular"} ><AddressLine address={Address} typeDisp="NoIcon" /></div> */}

                </div>


            </div>
        </>
    )
}


const deliveryBlock = (theTime, thePrice) => {
    return (
        <>
            <div className={"takeAwayBlock"}>
                <div className={"theImg"}>
                    <SportsMotorsports />
                </div>
                <div className={"txtOrdType"}>
                    <div className={"bold pickOrDel"} >משלוח</div>
                    <div className={"regular"} >{theTime}</div>
                    <div className={"regular"} >מחיר משלוח:  ₪{thePrice}</div>
                </div>
            </div>
        </>
    )
}


const clearOrd = () => {
    let ord = { restId: "", currentP: [], Orders: [] };

    let stringOrd = JSON.stringify({ ...ord });
    sessionStorage.setItem("am_order", stringOrd)

    return (ord);

}


//תוכן חלוקת המגש - בפיצריות
const pizzConten = (spec, item) => {
    if (spec.splitSelected != null) {
        let bk = "";
        switch (spec.splitSelected) {
            case "lHalf":
                {
                    bk = "חצי שמאל";
                    break;
                }
            case "rHalf":
                {
                    bk = "חצי ימין";
                    break;
                }
            case "rtQuart":
                {
                    bk = "רבע ימין עליון";
                    break;
                }
            case "rbQuart":
                {
                    bk = "רבע ימין תחתון";
                    break;
                }
            case "ltQuart":
                {
                    bk = "רבע שמאל עליון";
                    break;
                }
            case "lbQuart":
                {
                    bk = "רבע שמאל תחתון";
                    break;
                }
            default:
                bk = "כל המגש";
                break;

        }
        return (<span className={"regular"}>{bk}</span>)
    }
    else {
        return (<span className={"regular"}>{spec.itmsMount > 0 ? `X ${spec.itmsMount} ` : ""}</span>)
    }

}


//בודק אם יש רטבים מוצמדים ואם הלקוח עבר את המכסה החינמית
//במידה ועבר, יוחזר ההפרש שיש לתשלום 
const getStickedSauces = (theOrder) => {
    let priceToAdd = 0;
    let fSauces = (theOrder.freeSaucess != null) ? theOrder.freeSaucess : 0;
    let countIt = 0;
    theOrder.StickedSauces?.forEach(sauce => {

        for (let i = 0; i < sauce.mount; i++) {
            countIt++;
            if (countIt > fSauces) {
                priceToAdd += parseFloat((sauce.addToPrice != "") ? sauce.addToPrice : 0);
            }
        }

    });

    return priceToAdd;

}



export default {
    getOrderTotalPrice, getOrderPrice,
    takeAwayBlock, deliveryBlock, clearOrd, getStickedSauces,
    pizzConten

};