import React, { useContext, useEffect, useState } from 'react'
import parse from 'html-react-parser';
import utilsContactPage from '../../../_Utils/utilsContactPage';
import myFunctions from '../../../myFunctions';

const SectionContact = (props) => {

    const [inputsParams, setInputsParams] = useState([])
    const [erMsg, setErMsg] = useState("");
    const [showSendBtn, setShowSendBtn] = useState(true)
    const [sending, setSending] = useState(false)

    useEffect(() => {

        clearVals();

    }, [])


    const setitmParmVal = (e, indx) => {
        let tempo = [...inputsParams];
        tempo[indx].val = e.target.value;
        setInputsParams(tempo)
    }


    const switchesBlock = () => {

        let bk = [];
        if (inputsParams.length > 0) {
            bk = inputsParams.map((itm, indx) => {
                if (itm.checked) {
                    if (itm.name !== "תוכן הודעה") {
                        return (
                            <div key={indx} >
                                <input type={"text"}
                                    className={"contactInput"}
                                    style={{
                                        backgroundColor: props.item.contactBlocksTxtBgColor || "",
                                        color: props.item.contactBlocksTxtColor || ""
                                    }}
                                    value={itm.val}
                                    placeholder={itm.name} onChange={(e) => { setitmParmVal(e, indx) }} />
                                <br />
                            </div>
                        )
                    }
                    else {
                        return (
                            <div key={indx} >
                                <textarea
                                    className={"contactInput"}
                                    style={{
                                        backgroundColor: props.item.contactBlocksTxtBgColor || "",
                                        color: props.item.contactBlocksTxtColor || ""
                                    }}
                                    value={itm.val}
                                    placeholder={itm.name} onChange={(e) => { setitmParmVal(e, indx) }}
                                ></textarea>
                                <br />
                            </div>
                        )
                    }
                }
            })
        }

        return bk;

    }

    const sendMsgg = async () => {

        let fullName = "";
        let phoneNum = "";
        let email = "";
        let txtAre = "";
        let errMsg = "";


        inputsParams.forEach(element => {
            if (errMsg == "") {
                if (element.checked) {
                    if (element.name == "שם פרטי") {
                        if (element.val != "") {
                            fullName = element.val
                        }
                        else {
                            errMsg += "<p class='noSpace'>יש להזין שם פרטי</p>"
                        }
                    }
                    else if (element.name == "שם משפחה") {
                        if (element.val != "") {
                            fullName += " " + element.val
                        }
                        else {
                            errMsg += "<p class='noSpace'>יש להזין שם משפחה</p>"
                        }
                    }
                    else if (element.name == "טלפון") {
                        if (element.val != "") {
                            let chk = myFunctions.validPhone(element.val);
                            if (chk == "") {
                                phoneNum = element.val
                            }
                            else {
                                errMsg += "<p class='noSpace'>יש להזין מספר טלפון תקין</p>"
                            }
                        }
                        else {
                            errMsg += "<p class='noSpace'>יש להזין מספר טלפון תקין</p>"
                        }
                    }
                    else if (element.name == "אימייל") {
                        if (element.val != "") {
                            let chk = myFunctions.validEmail(element.val);
                            if (chk == "") {
                                email = element.val
                            }
                            else {
                                errMsg += "<p class='noSpace'>יש להזין אימייל תקין</p>"
                            }
                        }
                        else {
                            errMsg += "<p class='noSpace'>יש להזין אימייל תקין</p>"
                        }
                    }
                    else if (element.name == "תוכן הודעה") {
                        if (element.val != "") {
                            txtAre = element.val
                        }
                        else {
                            errMsg += "<p class='noSpace'>יש להזין תוכן להודעה</p>"
                        }
                    }
                }
            }
        });

        if (errMsg == "") {
            setSending(true)
            let msgg = {
                fullName, phoneNum, email, txtAre
            }
            let sndMsg = await utilsContactPage.sendMsg(msgg);
            setShowSendBtn(sndMsg.msg != "ההודעה נשלחה")
            setErMsg(sndMsg.msg)
            setSending(false)
        }
        else {

            setErMsg(errMsg)
        }

    }
    const clearVals = () => {
        let lst = [];

        if (props.item.contactBlocksDisp?.length > 0) {
            props.item.contactBlocksDisp.map((itm, indx) => {
                lst.push({ ...itm, val: "" })
            })
        }

        setShowSendBtn(true)
        setInputsParams(lst)
    }

    return (
        <>

            <div className={"sectionBlock"} style={{

                left: props.item.blockLeft + "%",
                top: props.item.blockTop + "%",
                height: props.item.blockHeight + "%",
                width: props.item.blockWidth + "%",


            }}>

                <div className={"sectionBack"} style={{
                    backgroundColor: props.item.blockColor,
                    opacity: props.item.blockTransparent
                }}>
                </div>

                <div className={"IN_sectionBlock"}>
                    <div
                        style={{
                            color: props.item.contactBlocksHead_txtColor
                        }}
                        className={`mainHead ${props.showEffects ?
                            `animate__animated animate__${props.item.contactBlocksHead_effect || ""}` : ""}
                         ${props.item.contactBlocksHead_delay ? ` ${props.item.contactBlocksHead_delay || ""}` : ""}
                         ${props.item.contactBlocksHead_duration ? ` duration${props.item.contactBlocksHead_duration || ""}` : ""}`}>
                        {props.item.contactBlocksHead_Text}
                    </div>
                    <div
                        style={{
                            color: props.item.contactBlocksStart_txtColor
                        }}
                        className={`smalltxt ${props.showEffects ?
                            `animate__animated animate__${props.item.contactBlocksStart_effect || ""}` : ""}
                         ${props.item.contactBlocksStart_delay ? ` ${props.item.contactBlocksStart_delay || ""}` : ""}
                         ${props.item.contactBlocksStart_duration ? ` duration${props.item.contactBlocksStart_duration || ""}` : ""}`}>
                        {parse(props.item.contactBlocksStart_Text)}
                    </div>
                    <div className={"contactInputsDiv"}>
                        {switchesBlock()}

                        {inputsParams.some(item => item.checked === true) ?
                            (!sending) ?

                                <input type={"button"} className={"sendMsgBtn"} value={"שלח"} onClick={() => sendMsgg()} />
                                : <div className={"erMsg"}>שולח...</div>
                            : ""}
                        {(!showSendBtn) ? clearVals() : ""}

                    </div>
                    <div
                        style={{
                            color: props.item.contactBlocksEnd_txtColor
                        }}
                        className={`smalltxt ${props.showEffects ?
                            `animate__animated animate__${props.item.contactBlocksEnd_effect || ""}` : ""}
                         ${props.item.contactBlocksEnd_delay ? ` ${props.item.contactBlocksEnd_delay || ""}` : ""}
                         ${props.item.contactBlocksEnd_duration ? ` duration${props.item.contactBlocksEnd_duration || ""}` : ""}`}>
                        {parse(props.item.contactBlocksEnd_Text)}
                    </div>

                    <div className={"erMsg"}>{parse(erMsg)}</div>
                </div>
            </div>
            <div className='WebByAsh'><p>נבנה ופותח ע"י <a href='https://www.ashdodmenu.co.il/Contact' target='_blank' >אשדוד בתפריט</a></p></div>
        </>


    );
}

export default SectionContact;