import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import { ContactlessOutlined, SwipeSharp } from '@mui/icons-material';
import SwiperCore, { Pagination } from 'swiper';
import ConnUrls from '../Connections/ConnectURL';
import { CurrentPContext, MasterContext } from '../ContextApi/context';

SwiperCore.use([Pagination]);


const TopBigSlides = (props) => {
  const [master, setMaster] = useContext(MasterContext);
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  
  const [timgs, setTimgs] = useState(props.TopImages);

  useEffect(() => {

  }, []);




  return (
    <div className={"HPTopImages"}>
      <Swiper
        loop={true}
        freeMode={false}
        className={"HPTopSwiper"}
        spaceBetween={0}
        slidesPerView={1}
        pagination={true}
      >
        {
          timgs.map((item, index) => {
            return (
              <SwiperSlide key={index} className={"HPTopImagesLst"}>

                {/* <Link to={`/cat/${item._id}`}> */}
                <div className={"HPTImgWrap"}>
                  <img className={"HPTopImage"} src={`${ConnUrls.servMediasUrl}${item.img}`}  alt={currentPage.altTags || currentPage.pageName || ""} />
                  <div className={"HPTopimgTxt bold big pointing"} >
                    {parse(`${item.txt}`)}
                  </div>

                </div>
                {/* </Link> */}
              </SwiperSlide>
            )

          })
        }

      </Swiper>

    </div>
  )

}

export default TopBigSlides;



