import React from 'react';
import { Router, Route } from 'react-router-dom';
import history from "./history";

const CreateRoutes = (Routes) => {

  let ruts = Routes.map((item, index) => {
    return (
      <Route key={index} path={item} sitemapIndex={true} />
    )
  })


  return (
    <Router history={history}>
      {ruts}
    </Router>
  )

}

export default CreateRoutes;