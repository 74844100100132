import parse from 'html-react-parser';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import ConnUrls from '../Connections/ConnectURL';
import { CurrentPContext, ScreenWidthContext } from "../ContextApi/context";

SwiperCore.use([Pagination]);
SwiperCore.use([Navigation]);
SwiperCore.use([Autoplay]);


const SwipeGallery = (props) => {
  const [currentPage, setCurrentPage] = useContext(CurrentPContext);

  const [timgs, setTimgs] = useState(props.imagesList);
  const [theSlides, setTheSlides] = useState();

  const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

  useEffect(() => {
    getslds()
  }, []);



  const getslds = () => {


    if (screenWidth <= 800) {
      return (<div className={"HPTopImages"}>
        <Swiper
          modules={[Pagination]}
          className={"HPTopSwiper"}
          spaceBetween={10}
          slidesPerView={1}
          pagination={true}
          loop={false}
          autoplay={{ delay: 3000 }}

          // onSlideChange={() => console.log('slide change')}
          freeMode={false}
        // onSwiper={(swiper) => console.log(swiper)}
        >
          {
            timgs.map((item, index) => {
              return (
                <SwiperSlide key={index} className={"HPTopImagesLst"}>
                  {item.img != null ?
                    // <Link to={item.lnk}>
                    <div className={"HPTImgWrap"}>
                      <img className={"HPTopImage"} src={`${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                      <div className={"HPTopimgTxt bold big pointing"} >
                        {/* {parse(`${item.txt}`)} */}
                      </div>
                    </div>
                    // </Link>
                    :
                    <>
                      <div className={"HPTImgWrap"}>
                        <img className={"HPTopImage"} src={`${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                        <div className={"HPTopimgTxt bold big pointing"} >
                          {/* {parse(`${item.txt}`)} */}
                        </div>
                      </div>
                    </>}
                </SwiperSlide>

              )

            })
          }

        </Swiper>

      </div >
      )
    }
    else {
      return (
        <div className={"HPTopImages"}>
          <Swiper
            modules={[Pagination]}
            className={"HPTopSwiper"}
            spaceBetween={10}
            slidesPerView={1}
            pagination={true}
            loop={true}
            autoplay={{ delay: 3000 }}
            initialSlide={props.startFrom || 0}

            // onSlideChange={() => console.log('slide change')}
            freeMode={false}
          // onSwiper={(swiper) => console.log(swiper)}
          >
            {
              timgs.map((item, index) => {
                return (
                  <SwiperSlide key={index} className={"HPTopImagesLst"}>
                    {/* <Link to={item.lnk}> */}
                    <div className={"HPTImgWrap"}>
                      <img className={"HPTopImage"} src={`${item.img}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                      <div className={"HPTopimgTxt bold big pointing"} >
                        {parse(`${item.txt}`)}
                      </div>

                    </div>
                    {/* </Link> */}

                  </SwiperSlide>
                )

              })
            }

          </Swiper>

        </div >
      )
    }

  }

  return (getslds())
}

export default SwipeGallery;



