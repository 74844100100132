
import { useContext, useEffect, useState } from 'react';
import { CurrentPContext, OrderContext, ScreenWidthContext } from '../../ContextApi/context';
import utilsCategurys from '../../_Utils/utilsCategurys';
import CategurysList from './CategurysList';
import { useHistory } from 'react-router';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { ArrowCircleDownSharp, ArrowBackIosNew, ArrowCircleUp, ArrowCircleRightSharp } from '@mui/icons-material';
import OrderFunctions from '../../OrderSettings/OrderFunctions';
import Scroll from 'react-scroll';
import { Prompt } from "react-router";
import ScrollingSaver from '../../DTechComp/ScrollingSaver';
import ConnUrls from '../../Connections/ConnectURL';
import parse from 'html-react-parser';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const CateguryPage = (props) => {
  const [order, setOrder] = useContext(OrderContext);

  const [currentPage, setCurrentPage] = useContext(CurrentPContext);
  const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

  const [loading, setLoading] = useState(true);
  const [allRestsOfCat, setAllRestsOfCat] = useState([]);
  const [cattxtOn, setCattxtOn] = useState(true);

  let { path, url } = useRouteMatch();

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    //מנקה את ההזמנה 
    setOrder(OrderFunctions.clearOrd());

    props.shoTopStrp(false)
    getCatPage();
    setLoading(false);
  }, [])


  const getCatPage = async () => {
    let categuryPage = await utilsCategurys.getCateguryById(props.pageId);
    setCurrentPage({ ...categuryPage, pageTYp: "" });
  }

  const goToBack = () => {
    sessionStorage.setItem("CateguryPage", "");
    history.goBack();

    // if (document.referrer.indexOf(window.location.host) === -1) {
    // }
    // else {
    //      history.push('/'); }
  }

  return (
    <div className="CateguryPage">
      {loading ? "" :
        <>
          {(screenWidth <= 800) ?
            <div className={"headOnFixC SecondFont bold big"}>
              {currentPage.catHead}
              <div className={`backSd`}>
                <ArrowCircleRightSharp onClick={() => goToBack()} />
              </div>
            </div> :
            ""}

          <br /><br /><br /><br />
          <div className={"fullWidth catTextBtn"}>

            {(screenWidth <= 800) ?
              <>
                <div className={"catTextBtnImg"} onClick={() => setCattxtOn(!cattxtOn)}>
                  <img src={`${ConnUrls.servMediasUrl}${currentPage.catImage}`} alt={currentPage.altTags || currentPage.pageName || ""} />
                </div>
                <div className={"ButtonMe"}
                  onClick={() => setCattxtOn(!cattxtOn)}>
                  {(!cattxtOn) ? "לפחות פרטים" : "לעוד פרטים"}
                </div>

              </>
              : <div className={"catTextBtnImg"} >
                <img src={`${ConnUrls.servMediasUrl}${currentPage.catImage}`} alt={currentPage.altTags || currentPage.pageName || ""} />
              </div>
            }
          </div>


          {(screenWidth <= 800) ?
            <div style={{ height: (!cattxtOn) ? `${(screenWidth / currentPage.catText.length) * 0.5} `:""}}
              className={`catText ${!cattxtOn ? "CattxtOn" : "categuryTxt"}`}
              onClick={() => setCattxtOn(!cattxtOn)}>
              {parse(`${currentPage.catText}`)}
            </div>
            :
            <div className={`catText CattxtOn`}>
              {parse(`${currentPage.catText}`)}
            </div>
          }

          <div><CategurysList pageId={props.pageId} /></div >

          {/* <Prompt
            message={(location, action) => {
              sessionStorage.setItem("scrol", document.scrollY)
              return true;
            }}
          /> */}
          < ScrollingSaver pagNam={"CateguryPage"} />
        </>

      }
    </div >
  );
}

export default CateguryPage;
