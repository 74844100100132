import React, { useEffect, useState, useContext } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import ConnUrls from '../../../Connections/ConnectURL';
import { MobiOrNotContext, ScreenWidthContext } from '../../../ContextApi/context';
import PopUpTheComp from '../../../DTechComp/PopUpTheComp';
import SwipeGallery from '../../../DTechComp/SwipeGallery';
import SwipeSlides from '../../../DTechComp/SwipeSlides';
import DesktopGallery from '../../insideObjects/DesktopGallery';
import MobileGallery from '../../insideObjects/MobileGallery';
import CustomDialogContent from '../../../DTechComp/CustomDialogContent';

const SectionGallery = (props) => {
    const [thePopUp, setThePopUp] = useState("");
    const [mobiOrNot] = useContext(MobiOrNotContext);


    useEffect(() => {

    }, [])


    const gallClick = (indx, e) => {

        let theGalls = Array.from(
            document.getElementsByClassName('GallBlock')
        );

        theGalls.forEach((elem, index) => {
            if (index != indx) {
                // elem.classList.remove('active-gal');
                elem.style.width = `${(30 / theGalls.length - 1)}%`;
            }
            else {
                // elem.classList.add('active-gal');
                elem.style.width = `${(80)}%`;
            }
        });

        let theSwips = Array.from(
            document.getElementsByClassName('smallGal')
        );

        theSwips.forEach((elem, index) => {
            if (index != indx) {
                elem.style.display = "none";
            }
            else { elem.style.display = "table"; }
        });

    }
    const showGall = (itm, indx) => {
        setThePopUp(
            <PopUpTheComp
                closePop={() => { setThePopUp() }}
                cssStyl={"gallSwipepop"}
                theInsideComp={
                    <SwipeGallery imagesList={itm.gallImages} startFrom={indx} />}
                Title={[""]}
                contentComponent={CustomDialogContent}
            />
        )
    }


    return (
        <>
            <div className={"sectionBlockWide"} style={{ backgroundColor: "transparent" }}>
                <div className={"IN_sectionBlock"} style={{ backgroundColor: "transparent" }}>
                    <div className={"fullRow acrodBlock"}>
                        {
                            (!mobiOrNot) ?
                                <DesktopGallery item={props.item} />
                                :
                                <MobileGallery item={props.item} />
                        }

                    </div>                    
                </div >
            </div >
        </>


    );
}

export default SectionGallery;