import React, { useContext, useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TheOrderList from '../OrderSettings/TheOrderList';
import Scroll from 'react-scroll';
import TheDInners from '../OrderSettings/TheDInners';
import { CheckCircleOutline, RadioButtonUnchecked } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { CurrentPContext, OrderContext } from '../ContextApi/context';
import { FormControlLabel } from '@mui/material';
import TheMoreThenOneMount from '../OrderSettings/TheMoreThenOneMount';
import DinersMoreThenOneMount from '../OrderSettings/DinersMoreThenOneMount ';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const PopUpDInnersCheck = (props) => {

    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [open, setOpen] = useState(true);
    const [inputTxt, setInputTxt] = useState("");
    const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);
    const [err, setErr] = useState((props.errorMsg == "") ? false : true);

    const [chupstiks, setChupstiks] = useState((order.getChupstiks != undefined) ? order.getChupstiks : false);
    const [fANife, setFANife] = useState((order.getfANife != undefined) ? order.getfANife : false);
    //רטבים מוצמדים ללקוח
    const [freeSaucess, setFreeSaucess] = useState(0);


    const [totSelected, setTotSelected] = useState(0);
    const [leftToSelect, setLeftToSelect] = useState(0);

    useEffect(() => {
        getSaucessOrderd();
    }, [])


    useEffect(() => {

        getSelectedSaccess();
        setLeftToSelect(freeSaucess - totSelected);

    }, [order])

    const getSelectedSaccess = () => {
        let temptotSelected = 0;

        order.StickedSauces?.forEach(itm => temptotSelected += parseInt(itm.mount))

        setTotSelected(temptotSelected);
    }

    //סכו"ם
    const setThefANife = (chk) => {

        let tempOrd = { ...order };
        tempOrd.getfANife = chk;
        setFANife(chk);
        setOrder({ ...tempOrd });
    }

    //צ'ופסטיקס
    const setTheChupstiks = (chk) => {

        let tempOrd = { ...order };
        tempOrd.getChupstiks = chk;
        setChupstiks(chk);
        setOrder({ ...tempOrd });
    }


    const getSaucessOrderd = () => {
        let tempfreeSaucess = 0;
        //כמה רטבים מוצמדים מגיע ללקוח לפי ההזמנה
        order.Orders.forEach((item, index) => {
            if (item.chkProdSauces) {
                tempfreeSaucess += (parseInt(item.chkProdValSauces) * item.mount);

            }
            item.specials?.forEach(specItem => {
                specItem.spec?.forEach(spItem => {
                    let temp = tempfreeSaucess;
                    try {
                        tempfreeSaucess += (parseInt(spItem.withSauces?spItem.withSauces:0) * parseInt(spItem.itmsMount) * item.mount);
                    }
                    catch { tempfreeSaucess = temp }
                });
            });
        })
        setFreeSaucess(tempfreeSaucess)
        setLeftToSelect(tempfreeSaucess);


        //מסמן בהזמנה כמה רטבים מוצמדים חינמיים מגיע
        let tempOrd = { ...order };
        tempOrd.freeSaucess = tempfreeSaucess;
        setOrder({ ...tempOrd });

    }

    const handleClose = () => {
        props.cancelClick();
    };


    const scrolUp = (scInt) => {
        scroll.scrollToTop(scInt);
    }

    //בודק אם יש להוסיף את הרוטב להצמדת הרטבים או לא
    const updateFreeSaucess = (item, chekd) => {

        let tempOrd = { ...order };

        if (chekd) {
            let fSauc = { servType: item.servType, mount: 1, addToPrice: item.addToPrice }
            if (tempOrd.StickedSauces == undefined) {
                tempOrd.StickedSauces = [fSauc]
            }
            else {
                tempOrd.StickedSauces.push(fSauc)
            }
        }
        else {
            let imIN = tempOrd.StickedSauces.findIndex(el => el.servType == item.servType);
            tempOrd.StickedSauces.splice(imIN, 1);
        }

        setOrder({ ...tempOrd });

    }


    //רטבים לבחירה, בחירה מרובה עדכון
    const updatMount = (item, newVal) => {
        let tempOrd = { ...order };

        let imIN = tempOrd.StickedSauces.findIndex(el => el.servType == item.servType);
        tempOrd.StickedSauces[imIN] = { servType: item.servType, mount: newVal, addToPrice: item.addToPrice }

        setOrder({ ...tempOrd });

    }


    return (
        <Dialog open={open} onClose={handleClose} className={"textSizeDiag  DinnersChkBlock"}>
            <DialogTitle className={"theTitlePopSmall headOnFixPop"}>
                {props.Title.map((txtItm, index) => {
                    return (
                        <span key={index} >{txtItm}<br /></span>
                    )
                })}</DialogTitle>
            <DialogContent>
                <table className={"DinnersChkTable"}>
                    <tbody>
                        <tr>
                            <td colSpan={2} className={"centerMe"}>
                                <div className={"SecondFont mnuDivPrdcbold onRight"}>
                                    מספר סועדים:
                                    <TheDInners />
                                </div>
                            </td>
                        </tr>
                        {currentPage.chkForkNife ?
                            <>
                                <tr>
                                    <td rowSpan={2} className={"chkItm"}>
                                        <Checkbox
                                            checked={fANife}
                                            onChange={() => setThefANife(!fANife)}
                                            icon={<RadioButtonUnchecked sx={{ color: 'var(--turqiText)' }} />}
                                            checkedIcon={<CheckCircleOutline sx={{ color: 'var(--turqiText)' }} />}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                            className={"chkBxChange rSideFirst"}
                                        />
                                    </td>
                                    <td className={"big SecondFontRegular"} onClick={() => setThefANife(!fANife)}>
                                        סכו"ם חד פעמי
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"FirstFont mediumFont"} onClick={() => setThefANife(!fANife)}>
                                        <span>
                                            אנא בחרו פריט זה כמספר הסועדים הזקוקים לסכו"ם</span>
                                        <br />
                                        <span>
                                            * במידה ולא סומן, פריט זה לא ישלח
                                        </span>
                                    </td>
                                </tr></> : ""}

                        {currentPage.chkChopStiks ?
                            <><tr>
                                <td colSpan={2} className={"spaceButtomBig"}></td>
                            </tr>
                                <tr>
                                    <td rowSpan={2} className={"chkItm"}>
                                        <Checkbox
                                            checked={chupstiks}
                                            onChange={() => setTheChupstiks(!chupstiks)}
                                            icon={<RadioButtonUnchecked sx={{ color: 'var(--turqiText)' }} />}
                                            checkedIcon={<CheckCircleOutline sx={{ color: 'var(--turqiText)' }} />}
                                            sx={{ '& .MuiSvgIcon-root': { fontSize: 25 } }}
                                            className={"chkBxChange rSideFirst"}
                                        />
                                    </td>
                                    <td className={"big SecondFontRegular"} onClick={() => setTheChupstiks(!chupstiks)}>
                                        צ'ופסטיקס
                                    </td>
                                </tr>
                                <tr>
                                    <td className={"FirstFont mediumFont"} onClick={() => setTheChupstiks(!chupstiks)}>
                                        <span>
                                            אנא בחרו פריט זה כמספר הסועדים הזקוקים לצ'ופסטיקס</span>
                                        <br />
                                        <span>
                                            * במידה ולא סומן, פריט זה לא ישלח
                                        </span>
                                    </td>
                                </tr></>
                            : ""}
                        {currentPage.chkSauces ?
                            <>
                                <tr>
                                    <td colSpan={2} className={"centerMe"}>
                                        <br />
                                        <div className={"SecondFont mnuDivPrdcbold onRight"}>
                                            רטבים לבחירה:
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={2} className={"FirstFont mediumFont"}>
                                        {(freeSaucess > 0) ? <>
                                            בהתאם להזמנה, לרשותך <span className={"huge"}>{freeSaucess}</span> רטבים לבחירה ללא עלות נוספת
                                            (כל רוטב נוסף יחוייב במחיר המוצג  לצידו)
                                        </> : ""}
                                        {currentPage.Sauces.map((itm, indx) => {

                                            let imIN = order.StickedSauces?.findIndex(el => el.servType == itm.servType);
                                            let chk = (order.StickedSauces == undefined || imIN == -1) ? false : true;
                                            let moun = (chk) ? order.StickedSauces[imIN].mount : 1;

                                            return (<div className={"TmChkSaucess"} key={indx}>
                                                <FormControlLabel control={
                                                    <Checkbox checked={chk}
                                                        onChange={() => updateFreeSaucess(itm, !chk)}
                                                        sx={{ color: 'var(--turqiText)' }}
                                                    />
                                                }
                                                    className={"FirstFont TmChk"}
                                                    label={itm.servType}
                                                    disabled={false}
                                                />
                                                {(chk) ?
                                                    <>
                                                        {(totSelected > 0 && totSelected > freeSaucess) ?
                                                            <div className={"saucAdPrice"}>{(itm.addToPrice != "") ? "+ " + itm.addToPrice + " ₪" : ""}</div> : <div className={"spacPrc"}></div>}

                                                        <div id={`S_` + indx} className={`ordItmLine TheMoreThenOneMount `}>
                                                            <DinersMoreThenOneMount css={"prodPlusMinusOnRight"}
                                                                Mount={moun}
                                                                itemId={itm.id}
                                                                changeVal={(newVal) => { updatMount(itm, newVal) }}
                                                                maxSelection={0}
                                                            />
                                                        </div>
                                                    </>
                                                    : ""}
                                            </div>)
                                        })}
                                    </td>
                                </tr>

                            </> : ""}
                    </tbody>
                </table>
            </DialogContent>
            <DialogActions >
                <button type="button" className="centerMe btn btn-success btnPopRegular yelowBtn pointing" onClick={() => { props.okClick(); handleClose(); }}>
                    {(props.BtnTxt != null) ? props.BtnTxt : "המשך"}</button>
            </DialogActions>
            <DialogActions >
                {props.btnClick2 != null ?
                    <>
                        <span>&nbsp;&nbsp;</span>
                        <button type="button" className="btn-success btnPopRegular DinnerBtn btnPopRegularSec pointing" onClick={() => { props.btnClick2(); handleClose(); }}>
                            {(props.BtnTxt2 != null) ? props.BtnTxt2 : "ביטול"}</button>
                    </>
                    : ""}

            </DialogActions>
        </Dialog >
    );
}

export default PopUpDInnersCheck;