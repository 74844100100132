import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'


const getRestMenu = async (obj) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "restMenu" ,{ params: obj });
    return (dFromF.data);
}


// const getMnuByRestId = async (obj) => {
//     let dFromF = await axios.get(ConnUrls.ApiUrl + "restMenu" ,{ params: obj });
//     return (dFromF.data);
// }

// const createRestMnu = async (obj) => {
//     let dFromF = await axios.post(ConnUrls.ApiUrl + "restMenu", obj);
//     return (dFromF.data);
// }


// const removeFromMenus = async (id) => {
//     let dFromF = await axios.delete(ConnUrls.ApiUrl + "restMenu/" + id);
//     return (dFromF);
// }


export default {
    getRestMenu
    // getMnuByRestId,
    // createRestMnu,
    // removeFromMenus
};