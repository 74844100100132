import jwt from "jsonwebtoken";
import tokCheck from "./_Utils/utilsCodes";


const checkToken = async (toeknToCheck) => {

    let theUsr = await tokCheck.checkToken(toeknToCheck);
    return theUsr;

}

const connCheck = async () => {
    const usrtoken = localStorage.getItem('lolusrtoken');

    if (usrtoken) {
        const user = jwt.decode(usrtoken);

        let usr = await checkToken(usrtoken);

        if (usr.user != null) {
            return usr;
        }
        else {
            localStorage.removeItem('lolusrtoken');
            return null;

            // goLogin();
        }

    }
    else {
        return null;
    }
}

const goLogin = () => {
    if (!window.location.href.includes('/LoginPage') && !window.location.href.includes('/resPass')) {
        return window.location.href = '/LoginPage'
    }

}


const randomImgPos = () => {

    let tp = Math.floor(Math.random() * 100);
    let lf = Math.floor(Math.random() * 100);

    return (tp + "% " + tp + "%")
}
const sortAsc = (arr, field) => {
    return arr.sort((a, b) => {
        if (a[field] > b[field]) { return 1; }
        if (b[field] > a[field]) { return -1; }
        return 0;
    })
}


const setSHuffled = (rests, lstBack) => {
    let randomIndex = Math.floor(Math.random() * rests.length);
    lstBack.push(rests[randomIndex]);
    rests.splice(randomIndex, 1);
    if (rests.length > 0) {
        lstBack = setSHuffled(rests, lstBack)
    }

    return lstBack;
}


const setTheImgWait = (curPage, master) => {

    let bgImgPos = (randomImgPos());

    let imgDelay = curPage.imgDelay ? curPage.imgDelay : master.imgDelay;


    let waitImg = "";

    let imgBackPos = "";
    let backgroundSize = "";
    let backgroundRepeat = "";
    let imgWaitingSpecial = "";

    if (curPage.waitingImg != null && curPage.waitingImg != "") {
        waitImg = curPage.waitingImg;
        imgWaitingSpecial = "imgWaitingSpecial"
    }
    else {
        waitImg = master.waitingImgMaster;
    }

    imgBackPos = curPage.imgPosRndom ? bgImgPos : 'center';
    backgroundSize = curPage.imgPosRndom ? 'auto' : 'contain';
    backgroundRepeat = !curPage.imgPosRndom ? 'no-repeat' : '';

    return ({ imgDelay, waitImg, imgBackPos, backgroundSize, backgroundRepeat, imgWaitingSpecial });
}



const searchMe = (txtSearch, searchList) => {
    txtSearch = (txtSearch == null) ? "" : txtSearch.trim();
    let lstBack = [];

    if (txtSearch != "") {
        let theLstBack = searchList.map((itm, index) => {

            if (itm.buissName.includes(txtSearch)) {
                if (lstBack.findIndex(ls => ls._id == itm._id) == -1) {
                    lstBack = [...lstBack, itm];
                }
            }
            else {

                itm.buisMenu.map((mnuItm, mnuItmIndex) => {
                    if (mnuItm.DivName.includes(txtSearch)) {
                        if (lstBack.findIndex(ls => ls._id == itm._id) == -1) {
                            lstBack = [...lstBack, itm];
                        }
                    }
                    else {
                        mnuItm.Products.map((mnuItmProd, mnuItmProdIndex) => {
                            if (mnuItmProd.prodName.includes(txtSearch)) {
                                if (lstBack.findIndex(ls => ls._id == itm._id) == -1) {
                                    lstBack = [...lstBack, itm];
                                }
                            }
                        })
                    }

                })

            }
        })

        return lstBack;
    }
    else {

        return searchList;
    }
}

//מתייחס לרשימות צ'ק בוקסס 
//בודק כמה פריטים נבחור עד כה ברשימה המתקבלת
const getTotalSelected = (theLst) => {
    let counting = 0;

    if (theLst.length > 0) {
        theLst.forEach((item, index) => {
            counting += item.itmsMount;
        });
    }

    return counting
}

//מתייחס לרשימות צ'ק בוקסס 
//בודק אם הרשימה עברה את המקסימום
const chkIfPasdMax = (maxSelection, selectedCounting) => {
    let counting = 0;

    if (maxSelection > selectedCounting) {
        return true;
    }
    else {

        return false
    }


}

const validPhone = (phone) => {
    if (phone !== "") {
        var clearPhone = phone.replace(/-/g, ''); // Replaces all hyphens with an empty string
        var pattern = /^[0-9]+$/;

        if (!pattern.test(clearPhone)) {
            return false;
        } else if
            (clearPhone.length !== 9 &&
            clearPhone.length !== 10 &&
            clearPhone.charAt(0) === '0') {
            return "<p>יש להזין מספר טלפון תקין</p>";
        } else {
            return "";
        }
    } else {
        return "<p>יש להזין מספר טלפון תקין</p>";
    }
};

// const validPhone = (phone) => {
//     if (phone !== "") {
//         var clearPhone = phone.replace('-', '');
//         var pattern = new RegExp(/^[0-9\b]+$/);
//         if (!pattern.test(clearPhone)) {
//             return false
//         } else if (clearPhone.length != 10 && clearPhone.length != 9) {
//             return "<p>יש להזין מספר טלפון תקין</p>";
//         }
//         else {
//             return "";
//         }
//     }
//     else {
//         return "<p>יש להזין מספר טלפון תקין</p>";
//     }
// }

const validEmail = (email) => {
    if (email !== "") {
        var pattern = new RegExp(/^[ ]*([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})[ ]*$/i);
        if (!pattern.test(email)) {
            return "<p>יש להזין כתובת מייל תקינה<p>";
        }
        else {
            return "";
        }
    }
    else {
        return "<p>יש להזין כתובת מייל תקינה<p>";
    }
}


const getOnlyaddress = (props) => {

    let enter = (props.address?.ent != null) ? props.address?.ent + " " : "";

    let mainAddress = props.address?.street + " " + props.address?.num + " " + enter + props.address?.city;

    return (
        { mainAddress }
    );
}






export default {
    setSHuffled,
    searchMe,
    sortAsc,
    randomImgPos,
    setTheImgWait,
    getTotalSelected,
    chkIfPasdMax, connCheck,
    validPhone,
    checkToken,
    validEmail,
    getOnlyaddress

};