import axios from "axios";

import ConnUrls from '../Connections/ConnectURL'

const getRests = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest");
    return (dFromF.data);
}

const getRestById = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/web/" + id);
    return (dFromF.data);
}

const getMainRestsByIds = async (idsList) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/web/byids" , idsList);
    return (dFromF.data);
}

const getRestNowOpen = async (obj) => {
    let dFromF = await axios.put(ConnUrls.ApiUrl + "rest/nop", obj);
    return (dFromF.data);
}

const getSerchResault = async (txtSrch) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "rest/txtSrch/" + txtSrch);
    return (dFromF.data);
}

export default {
    getRests,
    getMainRestsByIds,
    getRestNowOpen,
    getRestById,
    getSerchResault

};