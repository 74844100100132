import axios from "axios";
import ConnUrls from '../Connections/ConnectURL'


const getAllCategurys = async () => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "categurys");    
    return (dFromF);
}

const getCateguryById = async (id) => {

    let dFromF = await axios.get(ConnUrls.ApiUrl + "categurys/" + id);    
    return (dFromF);
}
const getRestsByCatId = async (id) => {
    let dFromF = await axios.get(ConnUrls.ApiUrl + "RestsByCat/" + id);    
    return (dFromF);
}



export default {
    getAllCategurys,
    getCateguryById,
    getRestsByCatId
};