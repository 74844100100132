import contactP from "../_ApiConn/apiContactPage";


const getContactPageById = async (idToGet) => {
    let dFromF = await contactP.getContactPageById(idToGet);
    return (dFromF.data);
}

const getPageByName = async (name) => {
    let dFromF = await contactP.getPageByName(name);
    return (dFromF.data[0]);
}

const allPgsNames = async () => {
    let dFromF = await contactP.allPgsNames();
    return (dFromF.data);
}

const sendMsg = async (msg) => {
    let dFromF = await contactP.sendMsg(msg);
    return (dFromF.data);
}

const createTableOrder = async (msg) => {
    let dFromF = await contactP.createTableOrder(msg);
    return (dFromF.data);
}

export default {
    getContactPageById,
    getPageByName,
    allPgsNames,
    sendMsg,
    createTableOrder
};