

import { ArrowCircleDownSharp, ArrowCircleLeft, ArrowCircleRightSharp, ArrowCircleUp, ContactsOutlined, LocalConvenienceStoreOutlined, LocalDining, ShoppingCartOutlined } from '@mui/icons-material';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft'
import { useContext, useEffect, useState } from 'react';
import { CurrentPContext, MasterContext, OrderContext, ScreenWidthContext, UserContext } from '../ContextApi/context';
import Checkbox from '@mui/material/Checkbox';
import CircleChecked from '@mui/icons-material/CheckCircleOutline';
import CircleCheckedFilled from '@mui/icons-material/CheckCircle';
import CircleUnchecked from '@mui/icons-material/RadioButtonUnchecked';
import Subject from '@mui/icons-material/Subject';
import Payment from '@mui/icons-material/Payment';
import Edit from '@mui/icons-material/Edit';
import EditOff from '@mui/icons-material/EditOff';



import TheMount from './TheMount';
import OrderFunctions from './OrderFunctions';
import { useHistory } from 'react-router';
import GoogleMapItem from '../DTechComp/GoogleMapItem';
import utilsRests from '../_Utils/utilsRests';
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';
import ContinueToPay from '../DTechComp/ContinueToPay';
import UserDetails from "../UsersLogs/UserDetails";
import utilsOrders from '../_Utils/utilsOrders';
import PopUpMsg from "../DTechComp/PopUpMsg";
import PopUpTheComp from "../DTechComp/PopUpTheComp";
import PopUpClearComp from "../DTechComp/PopUpClearComp";

import utilsUsers from '../_Utils/utilsUsers';
import UserAdresses from '../UsersLogs/UserAdresses';
import Scroll from 'react-scroll';
import utilsGamma from '../_Utils/utilsGamma';
import PopUpIframe from '../DTechComp/PopUpIframe';
import PayFrame from '../PaymentFrame/PayFrame';
import MySpinner_Wait from '../DTechComp/MySpinner_Wait';
import MyDateTime from '../DTechComp/MyDateTime';
import BigPopUpMsg from '../DTechComp/BigPopUpMsg';
import ConnUrls from '../Connections/ConnectURL';
import Minimum_Order from '../DTechComp/Pop_Fixed/Minimum_Order';
import Closed_Buis from '../DTechComp/Pop_Fixed/Closed_Buis';
import Crdit_Problem from '../DTechComp/Pop_Fixed/Crdit_Problem';
import TAKE_Confirmd from '../DTechComp/Pop_Fixed/TAKE_Confirmd';
import Delivery_Confirmd from '../DTechComp/Pop_Fixed/Delivery_Confirmd';




import { Helmet } from 'react-helmet';


var scroller = Scroll.scroller;

const TheOrderList = (props) => {
    const [master, setMaster] = useContext(MasterContext);
    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [screenWidth, setScreenWidth] = useContext(ScreenWidthContext);

    const [user] = useContext(UserContext);
    const [thePopUp, setThePopUp] = useState("");

    const [yellowLineTxt, setYellowLineTxt] = useState("המשך לתשלום");

    const [payType, setPayType] = useState((order.payt != null) ? order.payt : "1");

    const [showContBtn, setShowContBtn] = useState(true);

    const history = useHistory();

    let popTiming = 0;


    useEffect(() => {


        props.shoTopStrp(false)
        window.scrollTo(0, 0);
        order["payt"] = (order.payt != null) ? order.payt : "1";

        settingTheYellowline();
        setCurrPage();

    }, [])

    const goToBack = () => {
        history.goBack();

        // if (document.referrer.indexOf(window.location.host) === -1) {
        // }
        // else {
        //      history.push('/'); }
    }


    const setCurrPage = async () => {

        if (currentPage.length <= 0) {
            let restPage = await utilsRests.getRestById(order.restId);
            setCurrentPage({ ...restPage, pageTYp: "withConPay" });

            if (!MyDateTime.checkIfBetweenHours(restPage).openOrCLose) {

                popTiming++;

                setThePopUp(
                    <Closed_Buis okClick={() => setThePopUp()}
                        master={master}
                        btnClick2={() => history.push(`/restaurants/${restPage.pageUrlFix != null &&
                            restPage.pageUrlFix != "" ? restPage.pageUrlFix : restPage._id}`)}
                        errorMsg={""}
                    />
                )

            }

        }



    }

    useEffect(() => {
        settingTheYellowline();

    }, [order])

    //כאשר מחובר, מגדיר את הטקסט על הכפתור הצבהוב בהתאם לאופי ההזמנה
    const settingTheYellowline = () => {
        //איסוף
        if (order.OrderType == "Take") {
            //מזומן או אשראי? 
            //יש היפוך בגלל העברית אנגלית 
            //1 == אשראי
            setYellowLineTxt((order.payt == "1") ? "המשך לתשלום" : "בצע הזמנה");
        }
        //משלוח
        else {

            setYellowLineTxt("המשך הזמנה");
        }

    }

    //הסתר או הצג כמות של מנה מסויימת 
    const showHideMount = (elemId) => {

        let mountItm = document.getElementById(elemId);

        if (mountItm.classList.contains("slideMeUp")) {
            mountItm.classList.remove("slideMeUp");
        }
        else {
            mountItm.classList.add("slideMeUp");
        }
    }

    //הצג או הסתר הערות על המנה
    const showHideRemarks = (elemId) => {
        let Remarks = document.getElementById(`${elemId}_remarks`);
        let RemarksItm = document.getElementById(`${elemId}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${elemId}_remarkstxLine`);

        if (Remarks.classList.contains("slideMeUp")) {
            Remarks.classList.remove("slideMeUp");
            RemarksItmTxt.classList.add("slideMeUp");
        }
        else {
            Remarks.classList.add("slideMeUp");
            RemarksItmTxt.classList.remove("slideMeUp");
        }
    }



    //הצג או הסתר הערות על ההזמנה
    const showHideOrderRemarks = () => {

        let Remarks = document.getElementById(`orderRemarksDIv`);
        let RemarksItmTxt = document.getElementById(`orderRemarksTxt`);

        if (Remarks.classList.contains("slideMeUp")) {
            Remarks.classList.remove("slideMeUp");
            RemarksItmTxt.classList.add("slideMeUp");
        }
        else {
            Remarks.classList.add("slideMeUp");
            RemarksItmTxt.classList.remove("slideMeUp");
        }
    }

    //הגדר את הטקסט על הערות להזמנה
    const setTheOrderRemarks = () => {

        let RemarksItmTxt = document.getElementById(`orderRemarksTxt`);

        let RemarksItmTxtLine = document.getElementById(`orderRemarksTxtLine`);
        RemarksItmTxtLine.textContent = RemarksItmTxt.value;

        order.orderRemarks = "<p>" + RemarksItmTxt.value + "</p>";
        setOrder({ ...order });

        showHideOrderRemarks();
    }
    //הגדר את הטקסט על הערות המנה
    const setTheTxtRem = (index) => {

        let RemarksItm = document.getElementById(`${index}_remarksTxt`);
        let RemarksItmTxt = document.getElementById(`${index}_remarkstxLine`);
        RemarksItmTxt.textContent = RemarksItm.value;
        order.Orders[index].Remarks = RemarksItm.value;
        setOrder({ ...order });

        showHideRemarks(`${index}`);
    }

    //המפה המוצגת
    let googleMap =
        <>
            <div className={"RestGoogleMap"}>
                <GoogleMapItem mapLink={order.GoogleLink} />
            </div>
        </>


    let orderOrTake = <>
        {
            (screenWidth > 800) ?
                <div className={"bold anak centerText"}>{currentPage.buissName}</div> : ""
        }
        {
            (order.OrderType == "Take") ?
                OrderFunctions.takeAwayBlock(`ההזמנה מוכנה בעוד ${parseInt(order.OrderTimes) + 5} - ${order.OrderTimes} דק'`, order.Address)
                :
                OrderFunctions.deliveryBlock(`ההזמנה תגיע בעוד ${parseInt(order.OrderTimes) + 10} - ${order.OrderTimes} דק'`, order.OrderDelPrice)
        }
    </>


    const connect = () => {
        history.push("/LoginPage")
    }

    const register = () => {
        setThePopUp(
            <PopUpClearComp BtnTxt={"סגור"}
                btnFStyle={"fatInBtn"}
                okClick={() => setThePopUp()}
                theInsideComp={
                    <UserDetails theInsideComp={""}
                        backFunc={true}
                        closeMeFuc={() => setThePopUp()}
                        shoTopStrp={(tf) => { }}
                        scrollToTop={(stUp) => { }} />
                } />)

        // setThePopUp(<UserDetails shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={() => { scrolUp() }}/>)
        // history.push(
        //     {
        //         pathname: '/UserDetails',
        //         // search: '?query=abc',
        //         // state: { shoTopStrp: 'some_value' }
        //     })

    }

    //מושך מספר הזמנה להזמנה החדשה
    const register_theOrder = async () => {
        order.totalPrice = OrderFunctions.getOrderTotalPrice(order);
        order.orderNUM = order.orderNUM || await utilsOrders.getCounter();


        setOrder({ ...order });
    }


    //יוצר את ההזמנה במערכת וממשיך להמשך לתשלום, איסוף או משלוח?
    const getAddressOrPay = async () => {

        let tempo = await register_theOrder();

        if (order.OrderType == "Take") {
            setToTake();
        }
        else {
            setToDeliver();
        }
        // setShowContBtn(false)
    }


    //המשך הזמנה כמשלוח
    const setToDeliver = async () => {

        //בודק אם מוגדר מינימום מחיר למשלוח
        //במידה וכן עובד לפי המחיר המוגדר
        //במידה ולא, עובד לפי המינימום המוגדר במאסטר
        let mindelP = (currentPage.Delivery.MiniDelPrice != null &&
            currentPage.Delivery.MiniDelPrice != "") ?
            currentPage.Delivery.MiniDelPrice : master.MiniDelPrice;

        //רק במידה ומחיר המינימום למשלוח הושג. 
        if (parseInt(OrderFunctions.getOrderPrice(order)) >= parseInt(mindelP)) {

            popTiming++;
            setThePopUp(<div key={popTiming} id={"addressBox"} className={"addressBox"}>
                <UserAdresses noHead={true} closeMe={() => setThePopUp()} addId={"SelectAdd"}
                    setTheAddress={(theSele) => setTheAddress(theSele)}
                    GoTOPay={() => payForDelivery()} /></div>)

            setTimeout(function () {
                scroller.scrollTo(`addressBox`, { smooth: true, offset: 0, duration: 1000 });
            }, 20)

        }
        else {

            popTiming++;
            setThePopUp(
                <Minimum_Order
                    okClick={() => {
                        setThePopUp(); backToRestMenu();
                    }}
                    mindelP={mindelP}
                    btnClick2={() => { setThePopUp(); }}
                />
            )
        }

    }


    const backToRestMenu = () => {
        history.push(`/restaurants/${currentPage.pageUrlFix != null && currentPage.pageUrlFix != "" ?
            currentPage.pageUrlFix : currentPage._id}`)
    }

    const payForDelivery = () => {
        getMoney();
    }

    function parseJwt(token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    };

    const getMoney = async () => {


        setThePopUp(<div className={"onTopOfScreen"}><MySpinner_Wait /></div>);

        if (order.payt != "2") {

            let ordrIT = await creatTempOrder();

            // // אשראי או ביט
            let gammaBk = await utilsGamma.goGama({ order: ordrIT.orderItm });


            if (gammaBk.data != 'ERROR') {
                if (gammaBk.success) {

                    setThePopUp(<PopUpIframe
                        cssStyl={""}
                        ContentText={["כנראה שלא התבצע חיוב",
                            "ניתן לנסות שוב",
                            "ובתור תחלופה, ניתן להמשיך עם ההזמנה",
                            "ולבצע תשלום במזומן"]}
                        theData={gammaBk.data}
                        BtnTxt={"אשלם במזומן"}
                        BtnTxt2={"אנסה שוב"}
                        okClick={() => {
                            paytypChanched(2);
                            order.payt = "2";
                            getMoney();
                            setThePopUp();
                        }}
                        btnClick2={() => {
                            setThePopUp();

                        }}


                    />)

                }
                else {
                    setThePopUp(crditProblem())
                }
            }
            else {
                setThePopUp(crditProblem())
            }
        }
        else { popApprovedOrder() }
    }


    let crditProblem = () => {
        return (<Crdit_Problem
            okClick={() => {
                paytypChanched(2);
                order.payt = "2";
                getMoney();

                setThePopUp();
                // popApprovedOrder();
            }}
            closePop={() => setThePopUp()} />
        )
    }

    //יוצר הזמנה חדשה
    const creatTheorder = async () => {

        let theNorder = await utilsOrders.orderMyFood({ order, user });

        console.log(theNorder)

        let temp = { ...order, orderId: theNorder.orderId }

        setOrder(temp)

        return theNorder;

    }

    const creatTempOrder = async () => {
        let theNorder = await utilsOrders.tempoOrder({ order, user });
        let temp = { ...order, orderId: theNorder.orderId }

        setOrder(temp)

        return theNorder;
    }

    //מאשר ויוצר הזמנה חדשה 
    const popApprovedOrder = async () => {

        let orderMyFood = await creatTheorder();

        // let ord = await utilsOrders.getOrder(ordreMyFood.orderId);
        if (order.OrderType == "Take") {
            popTiming++;
            setThePopUp(
                <TAKE_Confirmd order={order} key={popTiming}
                    okClick={() => { setThePopUp(); history.push("/MyOrders") }}
                    orderMyFood={orderMyFood} />
            )

        }
        else {
            popTiming++;
            setThePopUp(
                <Delivery_Confirmd order={order} key={popTiming}
                    okClick={() => { setThePopUp(); history.push("/MyOrders") }}
                    orderMyFood={orderMyFood} />
            )
        }
    }


    const setTheAddress = (theSele) => {


        order.deliveryAddress = theSele;

        //מכניס את הכתובת הרצוייה
        order["deliveryAddress"] = theSele;


    }


    //המשך הזמנה כאיסוף
    const setToTake = async () => {
        if (order.payt == "2") {

            order.totalPrice = OrderFunctions.getOrderTotalPrice(order);

            let orderMyFood = await utilsOrders.orderMyFood({ order, user });
            
                        
            if (orderMyFood.msg == "approved") {
                popTiming++;
                setThePopUp(
                    <TAKE_Confirmd order={order} key={popTiming}
                        okClick={() => { setThePopUp(); history.push("/MyOrders") }}
                        orderMyFood={orderMyFood} />
                )
            }
        }
        else {
            getMoney();
        }
    }

    //הודעה על כך שכרטיס האשראי חויב וההזמנה בביצוע
    const creditCardApprove = async (sucessId) => {

        order.totalPrice = OrderFunctions.getOrderTotalPrice(order);
        // let ordreMyFood = await utilsOrders.orderMyFood({ order, user });

        popTiming++;
        setThePopUp(<PopUpMsg key={popTiming} Title={["ההזמנה בוצעה"]}
            ContentText={["המסעדה מתחילה להכין את ההזמנה."
                , "ניתן יהיה להגיע לאסוף"
                , `בערך בעוד ${order.OrderTimes} דקות`
                , `מספר הזמנה: ${order.orderNumber}`
                , order.creditCrdApprov != "" && order.creditCrdApprov != undefined ?
                    "מספר אישור עסקה: " + order.creditCrdApprov : "חיוב לא בוצע"
                , "בתאבון!"]}
            inLabel={""}
            okClick={() => { setThePopUp(); history.push("/MyOrders") }}
            errorMsg={""} />)

    }

    ///הערות הזמנה
    let ordRemarks = <>
        <div className={"ordItmLine remarksLine pointing"} onClick={() => showHideOrderRemarks()}>
            <span className={"rSideFirst"}><Subject /></span>
            <span className={"bold rSideMiddle big"}>הערות נוספות להזמנה</span>
            <span className={"bold lSideSmall"}></span>
        </div>
        <div className={"ordItmLine remarksTxtLine"}>
            <span className={"rSideFirst"}></span>
            <span className={"rSideMiddle bold big"} id={`orderRemarksTxtLine`}></span>
            <span className={"lSideSmall"}></span>
        </div>
        <div id={`orderRemarksDIv`} className={"ordItmLine itemRemraks spacePadBig slideMeUp"}>
            <textarea id={`orderRemarksTxt`} className={"remarksTxt"}
                placeholder={"תרצה לציין עוד משהו? אולי קוד כניסה? או שיש כלב בחצר?"}>
            </textarea>
            <div className={"ButtonMe"} onClick={() => setTheOrderRemarks()}>שמור</div>
        </div>
    </>

    ///המוצרים בהזמנה
    let ordersItems = order.Orders.map((item, index) => {
        return (

            <div key={index} className={"ordItem spaceButtomBig "}>

                <div className={"ordItmLine"}>
                    <Checkbox
                        onChange={() => showHideMount(index)}
                        icon={<Edit sx={{ color: 'black' }} />}
                        checkedIcon={<EditOff sx={{ color: 'black' }} />}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                        className={"chkBxChange rSideFirst"}
                    />
                    <span className={"bold rSideMiddle"}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className={"tdmin10"}>
                                        <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>{item.mount}&nbsp;</span>
                                    </td>
                                    <td>
                                        <span className={"ordItemMount"}> {item.productName}</span>
                                    </td>
                                    <td></td>
                                </tr>
                            </tbody>
                        </table>


                    </span>
                    <span className={"bold lSideSmall"}>₪ {parseFloat(item.prodPrice) * parseInt(item.mount)}</span>
                </div>

                {/* edit mount */}
                <div id={index} className={"ordItmLine itemMount spacePadBig slideMeUp"}>
                    <span className={"bold itemMountRI"}>כמות:</span>
                    <span className={"bold itemMountLf"}><TheMount itemId={item.orderItemId} remFromOrder={() => { }} /></span>
                </div>

                {
                    (item.specials.length > 0) ?
                        item.specials.map((special, specialIndex) => {

                            let specCount = 0;
                            let specc = special.spec.map((spec, specIndex) => {

                                if (spec.addToPrice > -1) {
                                    specCount++;

                                    return (
                                        <div key={specIndex} className={"ordItmLine"}>
                                            <span className={"rSideFirst"}></span>
                                            <span className={"rSideMiddle"}>
                                                {OrderFunctions.pizzConten(spec, item)}
                                                <span className={"regular"}> {spec.servType}</span>

                                            </span>
                                            <span className={"bold lSideSmall"}>
                                                {spec.addToPrice > 0 ? "₪ " + ((parseInt(spec.addToPrice) * parseInt(spec.itmsMount)) * parseInt(item.mount)) + " +" : ""}
                                            </span>
                                        </div>
                                    )
                                }
                            })
                            if (specCount > 0) {
                                return (
                                    <div key={specialIndex} className={"ordItmSpecLine spacePadSmall"}>
                                        <div className={"ordItmOptHead spacePadSmall bold"} >{special.optionName}</div>
                                        {specc}
                                    </div>
                                )
                            }


                        })
                        : ""
                }
                <div className={"ordItmLine remarksLine"} onClick={() => showHideRemarks(index)}>
                    <span className={"rSideFirst"}><Subject /></span>
                    <span className={"bold rSideMiddle"}>הערות למטבח</span>
                    <span className={"bold lSideSmall"}></span>
                </div>
                <div className={"ordItmLine remarksTxtLine"}>
                    <span className={"rSideFirst"}></span>
                    <span className={"rSideMiddle"} id={`${index}_remarkstxLine`}></span>
                    <span className={"lSideSmall"}></span>
                </div>
                <div id={`${index}_remarks`} className={"ordItmLine itemRemraks spacePadBig slideMeUp"}>
                    <textarea id={`${index}_remarksTxt`} className={"remarksTxt"}
                        placeholder={"ספר לנו איך אתה אוהב את האוכל, יש בעיה עם אלרגנים?"}>
                    </textarea>
                    <div className={"ButtonMe"} onClick={() => setTheTxtRem(index)}>שמור</div>
                </div>

            </div>


        )
    })

    //רטבים מוצמדים מוזמנים
    let stickedSauces =
        order.StickedSauces?.length > 0 ?
            <div className={"spaceButtomBig ordLstSpesBlk "}>
                <div className={"smallIconWrap"}>
                    <img src={"/PermanentImages/suse-icon.png"} className={"smallIcon"} alt="" />
                </div>
                <span className={"bold fullRow "}>רטבים מוצמדים</span><span className={"veryVerySmall"}>&nbsp;{(order.freeSaucess > 0) ? `(${order.freeSaucess} ללא עלות)` : ""} </span>
                <span className={"bold lSideSmall"}>{OrderFunctions.getStickedSauces(order) > 0 ? OrderFunctions.getStickedSauces(order) + "  ₪" : ""} </span>

                {
                    order.StickedSauces?.map((sauce, indx) => {
                        return (
                            <div key={indx} className={"sucItem"}>

                                <span className={"rSideMiddle"}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td className={"tdmin10"}>
                                                    <span className={"engFont ordItemMount"}>X&nbsp;</span><span className={"ordItemMount"}>{sauce.mount}&nbsp;</span>
                                                </td>
                                                <td>
                                                    <span className={"ordItemMount"}> {sauce.servType}</span>
                                                </td>
                                                <td>

                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </span>
                            </div>
                        )
                    })
                }
            </div>
            : ""



    //מספר סועדים
    let Dinners =
        // (currentPage.chkForkNife || currentPage.chkChopStiks) ?
        (order.Dinners) ?
            <div className={"sucItem spacePadSmall"} >
                <span className={"rSideMiddle"}>
                    <span className={"bold"}>מספר סועדים: </span>
                    <span>{order.Dinners}</span>
                </span>
            </div>
            : "";

    // : "";

    //סכו"ם
    let ANife =
        (currentPage.chkForkNife) ?
            <div className={"sucItem spacePadSmall"}>
                <span className={"rSideMiddle"}><span className={"bold"}>סכו"ם חד פעמי: </span><span>{order.getfANife ? "כן" : "אין צורך "}</span></span>
            </div>
            : "";

    //צופסטיקס
    let chupstiks =
        (currentPage.chkChopStiks) ?
            <div className={"sucItem spacePadSmall"}>
                <span className={"rSideMiddle"}><span className={"bold"}>צ'ופסטיקס: </span><span>{order.getChupstiks ? "כן" : "אין צורך "}</span></span>
            </div>
            : "";


    //אופן תשלום
    let creditCard =
        <>
            <FormControl component="fieldset">
                <div className={"veryBig bold spaceRightVeryLong startNBlock"}>אופן תשלום</div>
                <RadioGroup row
                    aria-label="אופן תשלום"
                    value={payType}
                    name="payt"
                    onChange={(e) => paytypChanched(e.target.value)}
                >
                    <FormControlLabel value="1" className={"blackRadiolbl"} control={<Radio className={"blackRadio"} />} label="אשראי" />
                    <FormControlLabel value="3" className={"blackRadiolbl"} control={<Radio className={"blackRadio"} />} label="ביט" />
                    <FormControlLabel value="2" className={"blackRadiolbl"} control={<Radio className={"blackRadio"} />} label="מזומן" />
                </RadioGroup>
            </FormControl>

        </>

    //אופן התשלום השתנה ופה מעדכנים את ההזמנה
    const paytypChanched = (ptyp) => {
        setPayType(ptyp);
        let ordTemp = { ...order };
        ordTemp.payt = ptyp;
        setOrder({ ...ordTemp });

    }

    //סיכום מחירים
    let TotPrices =
        <>
            <div className={"veryBig bold spacePad startNBlock centerText"}>כל המחירים בשקלים וכוללים מע"מ</div>
            <div className={"ordItmLine totalTxt"}>
                <div className={"bold totalTxtR"}>סכום ההזמנה</div>
                <div className={"bold totalTxtL"}>{OrderFunctions.getOrderPrice(order)} ₪ </div>
            </div>

            {(order.OrderType == "Deliver") ?
                <div className={"ordItmLine totalTxt"}>
                    <div className={"bold totalTxtR"}>משלוח</div>
                    <div className={"bold totalTxtL"}>{order.OrderDelPrice} ₪ </div>
                </div> : ""}

            <div className={"ordItmLine totalTxt"}>
                <div className={"bold totalTxtR"}>סה"כ</div>
                <div className={"bold totalTxtL"}>{OrderFunctions.getOrderTotalPrice(order)} ₪ </div>
            </div>
        </>

    // הרשמה/התחברות/ המשך לתשלום 
    let QuickReg =
        <>
            {(user?._id == null) ?
                <div className={"startNBlock"}>

                    <div className="ContinueToPayLine" onClick={() => connect()}>
                        <div className={"getToKnowBtn bold big centerText"}>התחבר</div>
                    </div>

                    <div className={"getToKnowTxt"}>משתמש רשום? התחבר בSMS</div>
                    <br />

                    <div className={"ContinueToPayLine"} onClick={() => register()}>
                        <div className={"getToKnowBtn bold big centerText"}>הרשם</div>
                    </div>
                    <br />

                    <div className={"getToKnowTxt"}>בוא נכיר אותך קצת - הנתונים ישמרו בקניה הבאה</div>

                </div>
                :
                <div className={`ContinueToPayLine ${(showContBtn) ? "" : "hideMe"}`} onClick={() => getAddressOrPay()}>
                    <div className={"getToKnowBtn bold big centerText"}>{yellowLineTxt}</div>
                </div>
            }

        </>


    let totalDinners =
        (Dinners != "" || ANife != "" || chupstiks != "") ?
            <div className={"ordItmLine spaceAndLine"}>
                <span className={"rSideFirst"}><LocalDining /></span>
                <span className={"rSideMiddle"}>
                    {Dinners}
                    {ANife}
                    {chupstiks}
                </span>
                <span className={"bold lSideSmall"}></span>
            </div>
            : ""

    return (
        <>
            <Helmet>
                <script src="https://gpapi.gamaf.co.il/dist/gamapay-bundle.js" />
            </Helmet>

            {order.Orders.length <= 0 ? <div className={"noItmes huge centerText"}>אין פריטים להזמנה.</div> :

                <div className={"TheOrderList"}>
                    {(screenWidth <= 800) ?
                        <div className={"headOnFixC SecondFont bold big"}>
                            {currentPage.buissName}
                            <div className={`backSd`}>
                                <ArrowCircleRightSharp onClick={() => goToBack()} />
                            </div>
                        </div>
                        : ""}

                    {googleMap}
                    <div className={"TheOrderListIn ordLstWrap"}>
                        {
                            <div className={"backToRestMenu centerText pointing"}
                                onClick={() => backToRestMenu()} >
                                <span>חזור לתפריט</span>
                            </div>
                        }
                        {/* <div className={"veryBig bold spaceRightVeryLong"}>סוג ההזמנה</div> */}
                        {orderOrTake}
                        <div className={"veryBig bold spaceRightVeryLong"}>ההזמנה שלך</div>
                        {ordersItems}
                        {stickedSauces}
                        {totalDinners}
                        {ordRemarks}

                        {creditCard}
                        {TotPrices}
                        <br />
                        {QuickReg}
                    </div>

                    {/* <ContinueToPay theTxt={"המשך לתשלום"} nxUrl="" withPrice={true} /> */}
                    {/* <ContinueToPay theTxt={"המשך לתשלום"} withPrice={true} /> */}
                    {thePopUp}
                </div>
            }
        </>

    )


}
export default TheOrderList;

