

import { useContext, useEffect, useReducer, useState } from 'react';
import { CurrentPContext, OrderContext, OrdStageContext } from '../ContextApi/context';
import ShopingCratFloat from './ShopingCratFloat';
import { BrowserRouter as Router, Switch, Route, Link, useParams, useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router";

import OrderFunctions from '../OrderSettings/OrderFunctions';
import PopUpShopCart from './PopUpShopCart';
import { StackedLineChartRounded } from '@mui/icons-material';
import TheOrderList from '../OrderSettings/TheOrderList';
import Scroll from 'react-scroll';

var scroller = Scroll.scroller;
var scroll = Scroll.animateScroll;

const initialState = {
    stage: 0,
    showOrders: "hideOrds",
    theText: "המשך הזמנה",
    totPrice: 0 + " ₪"
}

const ContinueToPay = (props) => {
    let { path, url } = useRouteMatch();

    const [currentPage, setCurrentPage] = useContext(CurrentPContext);
    const [order, setOrder] = useContext(OrderContext);
    const [txtBtn, setTxtBtn] = useState(props.theTxt);
    const [showMe, setShowMe] = useState('hideOrds');
    const [nxUrl, setNxUrl] = useState((props.nxUrl != null) ? props.nxUrl : '/TakeOrDel');
    const history = useHistory();
    const [thePopUp, setThePopUp] = useState("");
    const [siteCart, setSiteCart] = useState({ showme: false });

    const [showTopMasterStrip, setShowTopMasterStrip] = useState(true);

    useEffect(() => {
        setShowMe((order.Orders.length > 0) ? 'showOrds' : 'hideOrds')
        // setNxUrl((nxUrl == '/TakeOrDel') ? '/MyOrder' : '/#');

    }, [order])


    const nextPage = () => {
        setShowMe((currentPage.pageTYp == "withConPay") ? 'showOrds' : 'hideOrds');

        history.push(nxUrl);

    }


    const scrolUp = (scInt) => {
        scroll.scrollToTop(scInt);
    }

    let popTiming = -1;

    const showShopCrt = () => {

        setSiteCart({
            theOrdLst:
                <TheOrderList isPopUp={true} shoTopStrp={(tf) => setShowTopMasterStrip(tf)} scrollToTop={(stUp) => { scrolUp(stUp) }} />,
            showme: true
        })


    }

    return (
        <>
            <div className={`ContinueToPayWrap  ${showMe}`}>
                <div className="ContinueToPayLine" onClick={() => nextPage()}>
                    <ShopingCratFloat />
                    <div className={"shopCrt"}>
                        {(siteCart.theOrdLst) ? siteCart.theOrdLst : ""}
                    </div>
                    <div className={"contToPayText bold big"}>{txtBtn}</div>
                    {(props.withPrice) ? <div className={"priceTxt bold big"}>{OrderFunctions.getOrderPrice(order) + " ₪"}</div> : ""}
                </div>
            </div>


        </>

    )

}
export default ContinueToPay;


