import React, { useContext, useEffect, useState } from 'react'
import parse from 'html-react-parser';

const SectionTextBlock = (props) => {


    useEffect(() => {

    }, [])
    return (
        <>

            <div className={"sectionBlock"} style={{

                left: props.item.blockLeft + "%",
                top: props.item.blockTop + "%",
                height: props.item.blockHeight + "%",
                width: props.item.blockWidth + "%",


            }}>

                <div className={"sectionBack"} style={{
                    backgroundColor: props.item.blockColor,
                    opacity: props.item.blockTransparent
                }}>
                </div>

                <div className={"IN_sectionBlock"}>
                    <div
                        style={{
                            color: props.item.mainHeadColor
                        }}
                        className={`mainHead ${props.showEffects ?
                            `animate__animated animate__${props.item.mainHeadEffect || ""}` : ""}
                         ${props.item.mainHeaddelay ? ` ${props.item.mainHeaddelay || ""}` : ""}
                         ${props.item.mainHeadduration ? ` duration${props.item.mainHeadduration || ""}` : ""}`}>
                        {props.item.mainHead}
                    </div>
                    <div
                        style={{
                            color: props.item.smalltxtColor
                        }}
                        className={`smalltxt ${props.showEffects ?
                            `animate__animated animate__${props.item.smalltxtEffect || ""}` : ""}
                         ${props.item.smalltxtdelay ? ` ${props.item.smalltxtdelay || ""}` : ""}
                         ${props.item.smalltxtduration ? ` duration${props.item.smalltxtduration || ""}` : ""}`}>
                        {parse(props.item.smalltxt)}
                    </div>
                </div>
            </div>

        </>


    );
}

export default SectionTextBlock;