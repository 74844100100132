import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import he from 'date-fns/locale/he';

const DatePick = forwardRef((props, ref) => {
  const [selectedDate, setSelectedDate] = useState(props.selectedDate);

  useEffect(() => {
    setSelectedDate(props.selectedDate);
  }, [props.selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    props.settingFunc(date);
  };

  // Expose a function to update selectedDate from parent component
  useImperativeHandle(ref, () => ({
    setSelectedDate,
  }));

  return (
    <div className='mydtPicker'>
      <DatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat="dd/MM/yyyy"
        locale={he}
        months={he.months}
        weekdaysShort={he.weekdaysShort}
        customInput={
          <CustomInput drpArrCss={props.drpArrCss}
            wrpaStyl={props.wrpaStyl}
            label={props.lbl || ""} Css={props.Css || ""}
            selectedDate={selectedDate} />
        }
        popperPlacement="bottom-start"
        className={`custom-datepicker ${props.Css}`}
      />
    </div>
  );
});

const CustomInput = ({ drpArrCss, wrpaStyl, label, Css, selectedDate, onClick }) => {
  const currentDate = new Date();
  const formattedDate = selectedDate.toLocaleDateString('he-IL');
  const isToday = selectedDate.toDateString() === currentDate.toDateString();

  return (
    <div style={wrpaStyl}>
      <input className={Css}
        value={label ? `${label}${isToday ? 'היום' : formattedDate}` : formattedDate}
        onClick={onClick}
        readOnly
      />
      <div className={`${drpArrCss} pointing`} onClick={onClick}
        style={{ backgroundImage: `url("/PermanentImages/drpArrowWh.png")` }}></div>
    </div>
  );
};

export default DatePick;
