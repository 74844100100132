import { useContext, useEffect, useRef, useState } from "react";
import ProductOptions from "./ForOrderOptions/ProductOptions";
import ScrollMe from "../../DTechComp/ScrollMe";
import { CurrentPContext, OrderContext } from "../../ContextApi/context";
import { HighlightOffOutlined } from '@mui/icons-material';
import TheMount from "../../OrderSettings/TheMount";
import ConnUrls from "../../Connections/ConnectURL";
import { Prompt } from "react-router";

const ProductItem = (props) => {

    const [order, setOrder] = useContext(OrderContext);
    const [currentPage, setCurrentPage] = useContext(CurrentPContext);

    const [theProduct, setTheProduct] = useState();
    const [duplicates, setDuplicates] = useState([]);
    const [btnAddToOrder, setBtnAddToOrder] = useState({ txt: "הוסף להזמנה", ordID: -1 });
    const [sizeOfimg, setSizeOfimg] = useState("small");
    const [imInOrder, setImInOrder] = useState(false);

    const [popMeUp, setPopMeUp] = useState(false);



    let [inOrderIndex, setInOrderIndex] = useState(-1);

    // const [specials, setSpecials] = useState([]);
    // const [addsPrice, setAddsPrice] = useState(0);

    let specials = [];
    let mountToOrd = 0;
    let addsPrice = 0;



    useEffect(() => {

        let chkInOrder = (order.restId == currentPage._id);
        if (chkInOrder) {

            let getItm = order.Orders.findIndex(
                el => el.ordProdID == props.itemId &&
                    el.orderDiviId == props.divitm._id)

            if (getItm != -1) {
                setInOrderIndex(getItm);
                setSizeOfimg("big");
                setImInOrder(true);
            }

        }

        if (!props.innerOrder) {

            let getOrdersItm = order.Orders.filter(
                (el, index) => {
                    if (el.orderItemId.startsWith(props.itemId) &&
                        el.orderDiviId == props.divitm._id) {
                        return el;
                    }

                })


            if (getOrdersItm?.length > 0) {

                let doplis = [];

                getOrdersItm.forEach(element => {
                    if (element != null) {
                        if (element.orderItemId != props.itemId) {
                            doplis.push(<ProductItem key={`${props.divitm._id}${doplis.length + 1}`}
                                itemId={`${props.itemId}${doplis.length + 1}`}
                                openDupli={true}
                                doDeliver={props.doDeliver}
                                doTakeAway={props.doTakeAway}
                                innerOrder={true}
                                iamNewDupli={false}
                                divitm={props.divitm} dops={doplis.length}
                                setSender={(senderId) => props.setSender(senderId)}
                                sender={props.sender}
                                onlyPhone={props.onlyPhone}
                            />)
                        }
                    }

                });

                setDuplicates(doplis)
            }
        }

        if (props.openDupli) {
            goBig(`${props.itemId}`);
            setTheProduct(smDisp());
            setImInOrder(true);
        }

        if (props.iamNewDupli) {
            firstTimeOrdClick();
            goBig(`${props.itemId}`);
        }



    }, [])

    useEffect(() => {
        setTheProduct(smDisp());
    }, [sizeOfimg, imInOrder, props.dispOnlyDelivery])



    //מעדכן עלות תוספות ואופציות מיוחדות למנה שלא הוזמנה עדיין
    const tempOrderProds = (prods) => {
        let tempAddsPrice = 0;

        prods.map((item, index) => {
            item.spec.map((prc, ind) => {
                tempAddsPrice = parseFloat(tempAddsPrice) + parseFloat(prc.addToPrice);
            })
        })

        specials = prods;
        addsPrice = tempAddsPrice;

    }


    let newDop = 0;

    const addDuplicate = () => {
        newDop++;

        let newDivItem = { ...props.divitm };

        newDivItem.prodOptions.forEach(opt => {
            opt.selected = [];
        });

        setDuplicates(...duplicates, <ProductItem key={`${props.divitm._id}${newDop}`}
            itemId={`${props.itemId}${newDop}`}
            openDupli={true}
            iamNewDupli={true}
            doDeliver={props.doDeliver}
            doTakeAway={props.doTakeAway}
            divitm={newDivItem} dops={newDop}
            setSender={(senderId) => props.setSender(senderId)} sender={props.sender}
        />)

        goBig(`${props.itemId}${newDop}`);

    }

    //מגדיל את התצוגה שלו
    const goBig = (theItmID) => {
        // setTimeout(function () {
        //     ScrollMe.ScrollMePlus(`prodBlockS_${theItmID}`, -50)
        //     setSizeOfimg("big");
        // }, 20)

    }

    //פעם ראשונה נלחץ על כפתור ההזמן
    const firstTimeOrdClick = () => {
        setImInOrder(true);
        addToOrder();
        setPopMeUp(true);

    }

    //מוסיף את האובייקט לרשימת ההזמנות
    const addToOrder = () => {
        let tmp = {
            ordProdID: props.itemId,
            productName: props.divitm.prodName,
            prodImageUrl: props.divitm.prodImageUrl,
            prodPrice: props.divitm.prodPrice,
            addsPrice: addsPrice,
            specials: specials,
            mount: parseInt(mountToOrd) + 1,
            orderItemId: props.itemId,
            cashRegMakat: props.divitm.cashRegMakat,
            orderDiviId: props.divitm._id,
            chkProdSauces: props.divitm.chkProdSauces ? props.divitm.chkProdSauces : false,
            chkProdValSauces: props.divitm.chkProdValSauces ? props.divitm.chkProdValSauces : ""
        };


        order.Orders.push(tmp);

        setInOrderIndex(order.Orders.length - 1);

        order.restId = currentPage._id;
        order.GoogleLink = currentPage.GoogleLink;
        setOrder({ ...order });
    }


    const removeFromOrder = () => {
        setImInOrder(false);
        setPopMeUp(false);


    }


    const smDisp = () => {

        let imgLnk = `${(props.divitm.prodImageUrl != null && props.divitm.prodImageUrl != "") ? props.divitm.prodImageUrl
            : (currentPage.mnuItemMainImg != null && currentPage.mnuItemMainImg != "") ? currentPage.mnuItemMainImg : ""}`
        imgLnk = (imgLnk != "") ? ConnUrls.servMediasUrl+"/" + imgLnk : "";

        return (
            <div id={`prodBlockS_${props.itemId}`} key={duplicates.length} className={`productDiv ${(sizeOfimg == "small") ? "prodSmall" : "proBig"} `}>
                {imgLnk != "" ?
                    <div className={`prodImageWrap ${(sizeOfimg == "small") ? "prodImageWrapSmall" : "prodImageWrapBig"} `}>
                        <div className={`prodcloseBtn ${(sizeOfimg == "small") ? "hideMe" : "showMe"} `}>
                            <img src={"/PermanentImages/55.png"} onClick={() => setSizeOfimg("small")} />
                            {/* <HighlightOffOutlined sx={{ fontSize: 30, color: 'white' }} onClick={() => setSizeOfimg("small")} /> */}
                        </div>
                        <img className={"prodImage"} src={imgLnk}
                            onClick={() => goBig(props.itemId)} alt={currentPage.altTags || currentPage.pageName || ""} />
                    </div> : ""}

                <div className={"prodDetails"}>
                    <div className={(sizeOfimg == "small") ? `SecondFontRegular mnuDivPrdcbold MbigFont` :
                        `SecondFont mnuDivPrdcbold `}
                        onClick={() => (sizeOfimg == "small") ? goBig(props.itemId) : setSizeOfimg("small")}>{props.divitm.prodName}{(imInOrder) ?
                            <div className={"imInOrdBtn"}> אני בהזמנה </div>
                            : ""}</div>
                    {
                        (sizeOfimg == "small") ?
                            <div className={"prodDetailsShort"}>
                                <div className={"mnuDivPrdcReg prodSText"} onClick={() => goBig(props.itemId)}>
                                    <div className={"inProdTxt"} >
                                        {props.divitm.prodProducts}
                                    </div>
                                    {props.divitm.prodProducts.length > 80 ?
                                        <span className={"dotesL"}>...</span> : ""}
                                </div>
                            </div> :
                            <div className={"prodDetailsLong spaceTopBig"}>
                                <div className={"mnuDivPrdcReg"}>{props.divitm.prodProducts}</div>
                                <div className={"priceln "}> ₪{props.divitm.prodPrice}</div>
                                {props.divitm.okToDeliver ? <>
                                    <div className={"prodOrderLine"}>
                                        <div className={"seType"}> <div className={"prodLineHead"}>{(imInOrder) ? "כמות" : ""}</div>
                                            {props.onlyPhone ? "" :
                                                (imInOrder) ? <TheMount itemId={props.itemId} remFromOrder={() => removeFromOrder()} />
                                                    :
                                                    <div className={"addToOrderBtn"}
                                                        style={{ display: `${imInOrder ? "none" : "block"} ` }}
                                                        onClick={() => firstTimeOrdClick()}>{btnAddToOrder.txt}
                                                    </div>
                                            }
                                        </div>

                                    </div>
                                    {(imInOrder) ?
                                        props.divitm.prodOptions.length > 0 ?
                                            <ProductOptions product={props.divitm} PopMeUp={popMeUp}
                                                tempOrderProds={(prods) => tempOrderProds(prods)}
                                                itemId={props.itemId}
                                                imInOrder={imInOrder}
                                                inOrderIndex={inOrderIndex} /> : ""
                                        : ""}
                                    {(imInOrder) ?
                                        <div className={"prodOrderLine"}>
                                            <div className={"seType"}>
                                                {props.divitm.prodOptions.length > 0 ?
                                                    <div className={"prodLineHead"} id={`${props.itemId} new `} onClick={() => addDuplicate()}>
                                                        עוד אחד כזה (עם שינויים במנה) <span className={"plusInLHead"}>+</span></div>
                                                    : ""}
                                            </div>
                                        </div> : ""}  </> : <div className="notForDelivery">להזמנה במסעדה בלבד</div>}


                            </div>
                    }

                    {(sizeOfimg == "small") ? <div className={`priceln  prodDetailsShort
    }`}> ₪{props.divitm.prodPrice}</div> : ""}
                </div>
            </div >
        )
    }


    return (
        <div key={props.itemId} id={props.itemId} className={`${props.myClass}`} style={{ position: "relative" }}>
            {props.myClass != "" ? <div className={"overLay"}></div> : ""}
            
            <div className={"prods"}>
                {theProduct}
            </div>
            <div className={"duplis"}>
                {duplicates}
            </div>
        </div>
    );
}

export default ProductItem;
