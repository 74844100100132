import { useEffect, useRef, useState } from "react";

import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

const steps = [
    'Select master blaster campaign settings',
    'Create an ad group',
    'Create an ad',
];


const StepperList = (props) => {

    const [stepsList, setStepsLisr] = useState(props.List);
    const RestMnuRef = useRef(0)

    const [thevalue, setThevalue] = useState(0)

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        // return () => window.removeEventListener("scroll", handleScroll);


    }, []);


    const handleScroll = () => {

        const currentScrollY = window.scrollY;
        if (RestMnuRef.current) {

            if (currentScrollY > RestMnuRef.current.offsetTop) {
                setThevalue(thevalue + 1)
            }
            else {

            }

            spyTheBlock(currentScrollY, RestMnuRef.current.offsetTop - 100);
        }
    }



    const spyTheBlock = (currentScrollY, startMnus) => {
        let alldivBlocks = window.document.getElementsByClassName("stepper");

        if (startMnus < currentScrollY) {
            for (let i = 0; i < alldivBlocks.length; i++) {
                const element = alldivBlocks[i];
                if (currentScrollY < element.offsetTop + element.offsetHeight - 150) {
                    setThevalue(i);
                    break;
                }
            }
        }

    }


    return (
        <Box sx={{ width: '100%' }}>
            <Stepper className={"stepper"} ref={RestMnuRef} activeStep={-1} orientation="vertical">
                {stepsList.map((item, index) => (
                    <Step className={"stepperStep"} key={index}>
                        <StepLabel></StepLabel>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
}
export default StepperList;

